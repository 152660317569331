// Timer.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Timer = ({ duration, onExpire, restartTimer=false }) => {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    if (seconds === 0) {
      onExpire();
      return;
    }

    const intervalId = setInterval(() => {
      setSeconds(prev => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, onExpire]);

  useEffect(() => {
    if (seconds === 0) {
      setSeconds(duration)
    }
  }, [restartTimer])

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <span className='me-2'>{formatTime(seconds)}</span>
  );
};

Timer.propTypes = {
  duration: PropTypes.number.isRequired,
  onExpire: PropTypes.func.isRequired,
};

export default Timer;
