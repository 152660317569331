import React from "react";

const TextAvatar = ({ text, style,  clsName="text-avatar" }) => {
  function getRandomBg() {
    const cls = ['bg-success', 'bg-primary', 'bg-info', 'bg-secondary', 'bg-dark', 'bg-danger']
    if (cls.length === 0) return undefined; // Handle empty array case
    const randomIndex = Math.floor(Math.random() * cls.length);
    return cls[randomIndex];
  }

  return <div className={`${clsName} ${getRandomBg()}`} style={style}>{text}</div>;
};

export default TextAvatar;
