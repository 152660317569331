import React, { useState, useEffect, useCallback } from "react";
import DataLoader from "../Loader";
import { getAdminFrequentVisitorApi } from "../../../../services/DashboardService";
import { Link } from "react-router-dom";

const AdminFrequentVisitorActveGatepass = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAdminFrequentVisitorApi();
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoading ? (
    <div className="row">
      <div className="col-sm-6">
        <DataLoader />
      </div>
      <div className="col-sm-6">
        <DataLoader />
      </div>
    </div>
  ) : data === null ? (
    <div>
      <h3>Something went wrong ...🤕</h3>
    </div>
  ) : (
    <div className="row">
      <div className="col-xl-6 col-md-6">
        <div className="card">
          <div className="card-body p-0 pb-4">
            <div className="table-responsive active-projects shorting">
              <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                <h4 className="heading mb-0">Active Gatepass</h4>
              </div>

              <div
                id="projects-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="projects-tbl"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Gatepass Number</th>
                      <th>Visitor Name</th>
                      <th>Visitor Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.active_gatepass.length > 0 ? (
                      data.active_gatepass.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Link to={`/gatepass/${item.id}`} className="fw-semibold">
                              {item.gatepass_number}
                            </Link>
                          </td>
                          <td>
                            <Link to={`/visitor/${item.visitor_id}`}>
                              {item.first_name}
                              {item.last_name}
                            </Link>
                          </td>
                          <td>{item.email}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} style={{textAlign:'center'}}>
                          <h4>No Record Found 🌥️</h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-6 col-md-6">
        <div className="card">
          <div className="card-body p-0 pb-4">
            <div className="table-responsive active-projects shorting">
              <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                <h4 className="heading mb-0">Most Frequent visitor</h4>
              </div>

              <div
                id="projects-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="projects-tbl"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Visit Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.frequent_visitor.length > 0 ? data.frequent_visitor.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <Link to={`/visitor/${item.id}`} className="fw-semibold">
                            {item.first_name}
                            {item.last_name}
                          </Link>
                        </td>
                        <td>{item.email}</td>
                        <td>
                          <span className="fw-bold">{item.visit_count}</span>
                        </td>
                      </tr>
                    )): (
                      <tr>
                        <td colSpan={3} style={{textAlign:'center'}}>
                          <h4>No Record Found 🌥️</h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminFrequentVisitorActveGatepass;
