import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import Pagination from "../../../constant/Pagination";
import TableLoaderEffect from "../../../constant/TableLoader";
import { getVisitorGatepassApi } from "../../../../services/UserService";
import ApprovalInfo from "../../Gatepass/ApprovalInfoModal";
import { formatDateRange } from "../../../../utils";
import DynamicSelectInput, { StaticSelectInput } from "../../../constant/SelectInput";
import DebounceSearchInput from "../../../constant/DebounceSearchInput";
import { CancelRequest } from "../../../../services/AxiosInstance";
import { connect } from "react-redux";
import CustomFlatipckrDateRange from "../../../constant/CustomFlatipckrDateRange";

const GatePassDetails = ({userRole, user}) => {

  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    visiting_start_date: "",
    visiting_end_date: "",
    status: "",
    query: "",
    department: ""
  });
  const [isClearAll, setIsClearAll] = useState(false);
  const recordsPage = 10;

  const fetchData = useCallback(() => {
    CancelRequest()
    setIsLoading(true);
    getVisitorGatepassApi(currentPage, recordsPage, id, filter)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
      })
      .catch((error) => {
        console.error(error.response?.data?.message || "Error fetching data");
      }).finally(() => {
        setIsLoading(false);
      });
  }, [currentPage, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleDateChange = (visiting_start_date, visiting_end_date) => {
      setFilter((prev) => ({
        ...prev,
        visiting_start_date,
        visiting_end_date
      }));
    setCurrentPage(1);
  };

  const handleSelectChange = (name, value, _) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }));
    setIsClearAll(false);
    setCurrentPage(1);
  };

  const handleSearchChange = (value) => {
    setFilter((prev) => ({
      ...prev,
      query: value
    }));
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="table-header">
        <h3 className="mb-sm-0 mb-2">Gatepass List</h3>
        <div className='table-left-header'>
          <div className='form-group me-sm-3 me-0'>
            <DebounceSearchInput handleSearchChange={handleSearchChange} />
          </div>
        </div>
      </div>
      <div className="row my-2 px-3">
        <div className="col-md-4 form-group">
          {userRole != "Admin" && !(userRole == "CISF" && user.department !== null) ?
            <DynamicSelectInput
              setParentInputValue={handleSelectChange}
              endpoint_name="search/department"
              name={"department"}
              label={"Department"}
              labelHint={false}
              id={"department"}
              isClearable={true}
              placeholder={"Select Department"}
            />
            : null}
        </div>

        <div className="col-md-4 form-group">
          <StaticSelectInput
            optionsList={[
              { label: "Approved", value: "Approved" },
              { label: "Pending", value: "Pending" },
              { label: "Rejected", value: "Rejected" },
            ]}
            setParentInputValue={handleSelectChange}
            name="status"
            isClearable={true}
            placeholder="Select Status"
            label={"Status"}
            labelHint={false}
            defaultValue={
              filter.status
                ? { label: filter.status, value: filter.status }
                : null
            }
            id="status"
            isSetEmpty={isClearAll}
          />
        </div>

        <div className="col-md-4">
          <CustomFlatipckrDateRange handleOnDateSelect={handleDateChange} />
        </div>
      </div>
      <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
        <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
          <thead>
            <tr>
              <th>Gatepass Number</th>
              <th>Visiting Date</th>
              <th>Department</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoaderEffect colLength={6} />
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td><Link to={`/gatepass/${item.id}`}>{item.gatepass_number}</Link></td>
                  <td>{formatDateRange(item.visiting_start_date, item.visiting_end_date)}</td>
                  <td>{item.approval[0]?.department?.name}
                    {item.approval.length > 1 && (<ApprovalInfo approvalList={item.approval} />)}
                  </td>
                  <td>
                    {item.approval[0]?.status === "Approved" ?
                      <span className='badge light border-0 badge-success badge-sm'>Approved</span> :
                      item.approval[0]?.status === "Pending" ? <span className='badge light border-0 badge-warning badge-sm'>Pending</span> :
                        <span className='badge light border-0 badge-danger badge-sm'>Rejected</span>}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {!isLoading && (
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "Visitor",
  user: state.auth.user || null
});
export default connect(mapStateToProps)(GatePassDetails);