import React, { useState, useRef, useEffect } from "react";
import { updateCoVisitorApi } from "../../../services/UserService";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const coVisitorsSchema = () => {
  return yup.object().shape({
    isEditable: yup.boolean().notRequired(),
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
    dob: yup.string().when(["isEditable"], ([isEditable], schema) => {
      return isEditable
        ? schema.notRequired()
        : schema
            .required("Date of Birth is required")
            .matches(
              /^\d{4}-\d{2}-\d{2}$/,
              "Date of Birth must be in the format YYYY-MM-DD"
            )
            .test(
              "age",
              "Visitor must be at least 14 years old",
              function (value) {
                if (!value) return false;
                const today = new Date();
                const birthDate = new Date(value);
                const age = today.getFullYear() - birthDate.getFullYear();
                return age >= 14;
              }
            );
    }),

    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    aadhaar_number: yup
      .string()
      .matches(/^\d{12}$/, "Aadhaar number must be exactly 12 digits")
      .when(["isEditable"], ([isEditable], schema) => {
        return isEditable
          ? schema.notRequired()
          : schema.required("Aadhaar number is required");
      }),
    gender: yup.string().when(["isEditable"], ([isEditable], schema) => {
      return isEditable
        ? schema.notRequired()
        : schema.required("Select gender");
    }),
    company_name: yup.string().when(["isEditable"], ([isEditable], schema) => {
      return isEditable
        ? schema.notRequired()
        : schema.required("Company name is required");
    }),
    designation: yup.string().when(["isEditable"], ([isEditable], schema) => {
      return isEditable
        ? schema.notRequired()
        : schema.required("Designation is required");
    }),
    profile_photo: yup
      .mixed()
      .nullable()
      .when(["isEditable"], ([isEditable], schema) => {
        return isEditable
          ? schema.notRequired()
          : schema
              .required("Profile photo is required")
              .test("fileSize", "File size must be below 1MB", (value) => {
                if (typeof value === "string" || value === null) return true;
                return value && value.size <= 1024 * 1024;
              })
              .test("fileFormat", "Only JPG/PNG files are allowed", (value) => {
                if (typeof value === "string" || value === null) return true;
                return (
                  value && ["image/jpeg", "image/png"].includes(value.type)
                );
              });
      }),
    aadhaar_photo: yup
      .mixed()
      .nullable()
      .when(["isEditable"], ([isEditable], schema) => {
        console.log("sssss", isEditable);
        return isEditable
          ? schema.notRequired()
          : schema
              .required("Aadhaar photo is required")
              .test("fileSize", "File size must be below 1MB", (value) => {
                if (typeof value === "string" || value === null) return true;
                return value && value.size <= 1024 * 1024;
              })
              .test("fileFormat", "Only JPG/PNG files are allowed", (value) => {
                if (typeof value === "string" || value === null) return true;
                return (
                  value && ["image/jpeg", "image/png"].includes(value.type)
                );
              });
      }),
  });
};

const CoVisitorEditModal = ({ handleAfterUpdate, covisit }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [aadhaarPhotoPreview, setAadhaarPhotoPreview] = useState(null);
  const isEditable = covisit.is_used_any_gatepass;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(coVisitorsSchema()),
    defaultValues: {
      id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      aadhaar_number: "",
      dob: "",
      gender: "",
      company_name: "",
      designation: "",
      profile_photo: "",
      aadhaar_photo: "",
      isEditable: isEditable,
    },
  });

  useEffect(() => {
    console.log("serror", errors);
  }, [errors]);

  const toggleModal = () => {
    if (showModal) {
      reset();
      setProfilePhotoPreview(null);
      setAadhaarPhotoPreview(null);
    } else {
      setValue("id", covisit.id);
      setValue("first_name", covisit.first_name);
      setValue("last_name", covisit.last_name);
      setValue("email", covisit.email);
      setValue("phone_number", covisit.phone_number);
      setValue("aadhaar_number", covisit.aadhaar_number);
      setValue("dob", covisit.dob);
      setValue("gender", covisit.gender);
      setValue("company_name", covisit.company_name);
      setValue("designation", covisit.designation);
      setProfilePhotoPreview(`${process.env.REACT_APP_BASE_URL}${covisit.profile_photo}`);
      setAadhaarPhotoPreview(`${process.env.REACT_APP_BASE_URL}${covisit.aadhaar_photo}`);
    }
    setShowModal(!showModal);
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePhotoPreview(URL.createObjectURL(file));
      setValue("profile_photo", file);
      clearErrors("profile_photo");
    }
  };

  const handleAadhaarPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAadhaarPhotoPreview(URL.createObjectURL(file));
      setValue("aadhaar_photo", file);
      clearErrors("aadhaar_photo");
    }
  };

  const onSubmit = async (formcovisit) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("id", formcovisit.id);
      formData.append("first_name", formcovisit.first_name);
      formData.append("last_name", formcovisit.last_name);
      formData.append("email", formcovisit.email);
      formData.append("phone_number", formcovisit.phone_number);
      if(!isEditable){
        formData.append("aadhaar_number", formcovisit.aadhaar_number);
        formData.append("dob", formcovisit.dob);
        formData.append("gender", formcovisit.gender);
        formData.append("company_name", formcovisit.company_name);
        formData.append("designation", formcovisit.designation);
          if (formcovisit.profile_photo instanceof File) {
          formData.append("profile_photo", formcovisit.profile_photo);
        }
        if (formcovisit.aadhaar_photo instanceof File) {
          formData.append("aadhaar_photo", formcovisit.aadhaar_photo);
        }
      }
      const resp = await updateCoVisitorApi(formData);
      handleAfterUpdate(resp.data.data);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <>
      <span className="text-primary fw-bold c-pointer" onClick={toggleModal}>
        {covisit?.first_name} {covisit?.last_name}
      </span>

      <Modal show={showModal} size="lg" onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Co-Visitor</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    className="form-control"
                    {...register("first_name")}
                  />
                  <p className="text-danger">{errors.first_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    className="form-control"
                    {...register("last_name")}
                  />
                  <p className="text-danger">{errors.last_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    placeholder="Enter email address"
                    className="form-control"
                    {...register("email")}
                  />
                  <p className="text-danger">{errors.email?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter phone number"
                    className="form-control"
                    {...register("phone_number")}
                  />
                  <p className="text-danger">{errors.phone_number?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter company name"
                    {...register("company_name")}
                    disabled={isEditable}
                  />
                  <p className="text-danger">{errors.company_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Designation"
                    {...register("designation")}
                    disabled={isEditable}
                  />
                  <p className="text-danger">{errors.designation?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Aadhaar Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter aadhaar number"
                    {...register("aadhaar_number")}
                    disabled={isEditable}
                  />
                  <p className="text-danger">
                    {errors.aadhaar_number?.message}
                  </p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("dob")}
                  />
                  <p className="text-danger">{errors.dob?.message}</p>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label">Gender</label>
                <div className="d-flex">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Male"
                      {...register("gender")}
                      disabled={isEditable}
                    />
                    <label className="form-check-label">Male</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Female"
                      {...register("gender")}
                      disabled={isEditable}
                    />
                    <label className="form-check-label">Female</label>
                  </div>
                </div>
                <p className="text-danger">{errors.gender?.message}</p>
              </div>
              <div className="row">
                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">Profile Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    style={{
                      border: "1px dashed #ccc",
                      width: "150px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: !isEditable ? "pointer" : "not-allowed",
                    }}
                    onClick={() =>
                      !isEditable &&
                      document.getElementById("profilePhotoInput").click()
                    }
                  >
                    {profilePhotoPreview ? (
                      <img
                        src={profilePhotoPreview}
                        alt="Profile Preview"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id="profilePhotoInput"
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={handleProfilePhotoChange}
                    disabled={isEditable}
                  />
                  {errors.profile_photo && (
                    <p className="text-danger">
                      {errors.profile_photo?.message}
                    </p>
                  )}
                </div>
                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    style={{
                      border: "1px dashed #ccc",
                      width: "150px",
                      height: "150px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: !isEditable ? "pointer" : "not-allowed",
                    }}
                    onClick={() =>
                      !isEditable &&
                      document.getElementById("aadhaarPhotoInput").click()
                    }
                  >
                    {aadhaarPhotoPreview ? (
                      <img
                        src={aadhaarPhotoPreview}
                        alt="Aadhaar Preview"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id="aadhaarPhotoInput"
                    type="file"
                    className="d-none"
                    accept="image/*"
                    onChange={handleAadhaarPhotoChange}
                    disabled={isEditable}
                  />
                  {errors.aadhaar_photo && (
                    <p className="text-danger">
                      {errors.aadhaar_photo?.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-secondary me-3"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CoVisitorEditModal;
