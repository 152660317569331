import React, { useState, useEffect, useCallback } from "react";
import { getAdminGatepassCountApi } from "../../../../services/DashboardService";
import ReactApexChart from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import DataLoader from "../Loader";

class GatepassCountDonutChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: props.series,
      isForApprover: props.isForApprover,
      options: {
        chart: {
          type: "donut",
          width: 200,
        },
        colors: ["#3AC977", "#FF9F00", "#00a0e3"],
        labels: [
          props.isAnApprover ? "Approved" : "Verified",
          "Pending",
          "Total",
        ],
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: 12,
                },
                value: {
                  show: true,
                  fontSize: "22px",
                  fontFamily: "Arial",
                  fontWeight: "500",
                  offsetY: -17,
                },

                total: {
                  show: true,
                  fontSize: "11px",
                  fontWeight: "500",
                  fontFamily: "Arial",
                  label: props.isAnApprover ? "Approved" : "Verified",
                  color: "#000",

                  formatter: function (w) {
                    return w.globals.seriesTotals[0] || 0;
                  },
                },
              },
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div className="d-flex align-items-center">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="donut"
          width={200}
          height={150}
        />
      </div>
    );
  }
}

const AdminGatepassCountWidget = ({ isAnApprover }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getAdminGatepassCountApi();
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleGatepassClick = (status) => {
    navigate("/gatepass", {
      state: { gatepass_status: status },
    });
  };

  return isLoading ? (
    <div className="row">
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
    </div>
  ) : data === null ? (
    <div>
      <h3>Something went wrong ...🤕</h3>
    </div>
  ) : (
    <div className="row">
      {isAnApprover && data.approval_queue_count > 0 ? (
        <div className="col-12">
          <div className="card text-danger border-0">
            <div className="card-body pt-2 d-flex justify-content-between align-item-start align-items-md-center flex-column flex-md-row">
              <div className="fs-4">
                <strong>{data.approval_queue_count}</strong> Gatepass
                {data.approval_queue_count > 1 ? "es are" : " is"} verified and
                awaiting for approval in the queue. Please take action as soon
                as possible.
              </div>
              <Link
                className="btn btn-sm btn-danger mt-2 mt-md-0"
                to={"/gatepass_approval_queue"}
              >
                View Approval Queue
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      <div className="col-xl-3 col-sm-6">
        <div className="card same-card">
          <div className="card-body d-flex align-items-center  py-2">
            <div>
              <h3 className="text-center">Gatepass Count </h3>
              <GatepassCountDonutChart
                series={[
                  data.approved_count,
                  data.pending_count,
                  data.approved_count + data.pending_count,
                ]}
                isAnApprover={isAnApprover}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-success">
          <div
            className="card-body cursor-pointer"
            onClick={() => handleGatepassClick("Approved")}
          >
            <h3 className="text-white text-center">
              {isAnApprover ? "Approved" : "Verified"} Gatepass
            </h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.approved_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-warning">
          <div className="card-body cursor-pointer"
            onClick={() => handleGatepassClick("Pending")}>
            <h3 className="text-white text-center">Pending Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.pending_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-primary">
          <div className="card-body cursor-pointer"
            onClick={() => navigate("/gatepass")} >
            <h3 className="text-white text-center">Total Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.approved_count + data.pending_count}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminGatepassCountWidget;
