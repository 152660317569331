import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "../../../constant/Pagination";
import TableLoaderEffect from "../../../constant/TableLoader";
import { getCOVisitorApi } from "../../../../services/UserService";
import FullscreenImage from "../../../constant/FullscreenImage";

const CoVisitor = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const recordsPage = 10;
  const [expandedRow, setExpandedRow] = useState(null); // Track the expanded row
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getCOVisitorApi(currentPage, recordsPage, id);
        const resp_data = resp.data.data;
        setData(resp_data);
        setTotal(resp.data.total || 0);
      } catch (error) {
        console.error("Error fetching gate pass details:", error);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, recordsPage, id]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const toggleRowExpansion = (id) => {
    // Toggle the expanded row state
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
    <div>
      <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
        <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone No.</th>
              <th>Gender</th>
              <th>More</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoaderEffect colLength={7} />
            ) : (
              data.map((item) =>
                item.covisitors?.map((covisit) => (
                  <React.Fragment key={covisit.id}>
                    <tr>
                      <td>
                        <span
                          className="text-primary fw-bold c-pointer"
                          onClick={() => navigate(`/gatepass/${covisit?.id}`)}
                        >
                          {covisit?.first_name} {covisit?.last_name}
                        </span>
                      </td>
                      <td>{covisit?.email}</td>
                      <td>{covisit?.phone_number}</td>
                      <td>{covisit?.gender}</td>
                      <td>
                        <button
                          className="btn btn-sm text-primary"
                          onClick={() => toggleRowExpansion(covisit.id)}
                          data-toggle="tooltip" data-placement="top" title="Details"
                        >
                          <i className="fa-solid fa-ellipsis-vertical fa-lg"></i>
                        </button>
                      </td>
                    </tr>
                    {expandedRow === covisit.id && (
                      <tr>
                        <td colSpan="5">
                          <div className="d-flex flex-sm-row flex-column align-items-sm-cente ralign-items-start justify-content-around">
                            <div className="mb-2">
                              <FullscreenImage 
                              src={`${process.env.REACT_APP_BASE_URL}${covisit.profile_photo}`} 
                              alt="Profile" 
                              className={"border rounded"}
                              thumbnailStyle={{ width: "100px", marginRight: "10px", objectFit: "cover" }} />
                            </div>
                            <div className="mb-2">
                              <FullscreenImage 
                              src={`${process.env.REACT_APP_BASE_URL}${covisit.aadhaar_photo}`} 
                              alt="Aadhaar" 
                              className={"border rounded"}
                              thumbnailStyle={{ width: "100px", objectFit: "cover" }} />
                            </div>
                            <div className="d-flex flex-column">
                              <span className="my-1"><strong>Date of Birth:</strong> {covisit.dob}</span>
                              <span className="my-1"><strong>Aadhaar Number:</strong>{covisit.aadhaar_number}</span>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="my-1"><strong>Company Name: </strong>{covisit.company_name}</span>
                              <span className="my-1"><strong>Designation:</strong> {covisit.designation}</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              )
            )}
          </tbody>
        </table>
      </div>
      {isLoading ? (
        <div style={{ height: "4rem" }}></div>
      ) : (
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      )}
    </div>
  );
};

export default CoVisitor;
