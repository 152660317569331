import React, { useState, useEffect, useCallback } from "react";
import { getCoVisitorListApi } from "../../../services/UserService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from "../../constant/TableLoader";
import { connect } from "react-redux";
import DebounceSearchInput from "../../constant/DebounceSearchInput";
import FullscreenImage from "../../constant/FullscreenImage";
import CoVisitorAddModal from "./AddCovisitor";
import CoVisitorEditModal from "./EditCovisitor";

const CoVisitorPage = ({ useRole }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const recordsPage = 25;

  const navigate = useNavigate();

  const fetchData = useCallback((page, query) => {
    setIsLoading(true);
    getCoVisitorListApi(page, recordsPage, query)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response?.data?.message || "Error fetching data");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setSearchQuery(value);
    setCurrentPage(1);
  };

  const handleAfterCreate = (newData) => {
    setData((prevData) => [newData, ...prevData]);
    setTotal((prevTotal) => prevTotal + 1);
  };

  const handleAfterUpdate = (updatedData) => {
    setData((prevData) =>
      prevData.map((item) => (item.id === updatedData.id ? updatedData : item))
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-header">
                <h3 className="mb-0">Co-Visitor List</h3>
                <div className="table-left-header">
                  <div className="form-group me-3">
                    <DebounceSearchInput
                      handleSearchChange={handleSearchChange}
                    />
                  </div>
                  <CoVisitorAddModal handleAfterCreate={handleAfterCreate} />
                </div>
              </div>
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <table
                  id="empoloyees-tblwrapper"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone No.</th>
                      <th>Gender</th>
                      <th>Company Name</th>
                      <th>Designation</th>
                      <th>DOB</th>
                      <th>Adhaar No.</th>
                      <th>Photos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoaderEffect colLength={9} />
                    ) : (
                      data.map((covisit) => (
                        <tr key={covisit.id}>
                          <td>
                            <CoVisitorEditModal covisit={covisit} handleAfterUpdate={handleAfterUpdate} />
                          </td>
                          <td>{covisit?.email}</td>
                          <td>{covisit?.phone_number}</td>
                          <td>{covisit?.gender}</td>
                          <td>{covisit?.company_name}</td>
                          <td>{covisit?.designation}</td>
                          <td>{covisit.dob}</td>
                          <td>{covisit.aadhaar_number}</td>
                          <td>
                            <FullscreenImage
                              src={`${process.env.REACT_APP_BASE_URL}${covisit.profile_photo}`}
                              alt="Profile"
                              thumbnailStyle={{
                                width: "30px",
                                marginRight: "10px",
                                objectFit: "cover",
                              }}
                            />
                            <FullscreenImage
                              src={`${process.env.REACT_APP_BASE_URL}${covisit.aadhaar_photo}`}
                              alt="Aadhaar"
                              thumbnailStyle={{
                                width: "30px",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>

              </div>
              {isLoading ? (
                <div style={{ height: "4rem" }}></div>
              ) : (
                <Pagination
                  currentPage={currentPage}
                  recordsPage={recordsPage}
                  dataLength={total}
                  handlePagination={handlePagination}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  useRole: state.auth.user?.role || "Visitor",
});

export default connect(mapStateToProps)(CoVisitorPage);
