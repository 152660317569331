import { SVGICON } from "../../constant/theme";
import {
    ALL_USER_PERM,
    ONLY_ADMIN_PERM,
    ONLY_CISF_PERM,
    ONLY_VISITOR_PERM,
    ONLY_SUPER_ADMIN_PERM,
    EXCEPT_VISITOR_PERM_PERM,
    EXCEPT_VISITOR_CISF_PERM_PERM
} from "../../permission";
export const MenuList = [

    {
        title: 'Dashboard',
        iconStyle: SVGICON.MenuDashboard,
        to: '/dashboard',
        accessRole: ALL_USER_PERM
    },
    {
        title: 'Gatepass',
        iconStyle: SVGICON.MenuReport,
        to: '/gatepass',
        accessRole: ALL_USER_PERM
    },
    {
        title: 'User',
        iconStyle: SVGICON.MenuUsers,
        to: '/user',
        accessRole: EXCEPT_VISITOR_CISF_PERM_PERM
    },
    {
        title: 'CISF',
        iconStyle: SVGICON.MenuCISF,
        to: '/cisf',
        accessRole: ONLY_SUPER_ADMIN_PERM
    },
    {
        title: 'Visitor',
        iconStyle: SVGICON.MenuVisitors,
        to: '/visitor',
        accessRole: EXCEPT_VISITOR_PERM_PERM
    },
    {
        title: 'Gate',
        iconStyle: SVGICON.MenuLayout,
        to: '/gate',
        accessRole: ONLY_SUPER_ADMIN_PERM
    },
    {
        title: 'Device',
        iconStyle: SVGICON.MenuDevice,
        to: '/device',
        accessRole: ONLY_SUPER_ADMIN_PERM
    },
    {
        title: 'Visiting Log',
        iconStyle: SVGICON.MenuVisitingLog,
        to: '/visiting-log',
        accessRole: EXCEPT_VISITOR_PERM_PERM
    },
    {
        title: 'Report',
        iconStyle: SVGICON.MenuReportGraph,
        to: '/report',
        accessRole: EXCEPT_VISITOR_PERM_PERM
    },
    {
        title: 'Dept. & Desig.',
        iconStyle: SVGICON.MenuDepartment,
        to: '/department-designation',
        accessRole: ONLY_SUPER_ADMIN_PERM
    },
    {
        title: 'Co-Visitor',
        iconStyle: SVGICON.MenuCoVisitors,
        to: '/co-visitor',
        accessRole: ONLY_VISITOR_PERM
    },
    {
        title: 'Returnable Items',
        iconStyle: SVGICON.MenuReturnableItem,
        to: '/returnable-items',
        accessRole: ONLY_VISITOR_PERM
    },
]