import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getExstingCovisitorListApi,
  getExstingVehicleListAPi,
  getExstingReturnableItemsListApi,
} from "../../../../services/UserService";
import { StaticSelectInput } from "../../../constant/SelectInput";

const ChooseExistingDataModal = ({
  handleAfterSelect,
  selectType = "Covisitor",
  excludeId = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionList, setOptionList] = useState([]);

  const fetchCovisitor = async () => {
    try {
      setLoading(true);
      const resp = await getExstingCovisitorListApi();
      const data = resp.data.data;
      setOptionList(
        data.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name} (${item.email})`,
          };
        })
      );
      setDataList(data);
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const fetchReturnableItem = async () => {
    try {
      setLoading(true);
      const resp = await getExstingReturnableItemsListApi();
      const data = resp.data.data;
      setOptionList(
        data.map((item) => {
          return {
            value: item.id,
            label: `${item.item_name} (${item.description})`,
          };
        })
      );
      setDataList(data);
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const fetchVehicle = async () => {
    try {
      setLoading(true);
      const resp = await getExstingVehicleListAPi();
      const data = resp.data.data;
      setOptionList(
        data.map((item) => {
          return {
            value: item.id,
            label: `${item.vehicle_number} (DL-${item.driving_licence_number}${
              item.is_self_drive ? " Self Drive" : ""
            })`,
          };
        })
      );
      setDataList(data);
    } catch (error) {
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const toggleModal = () => {
    if (showModal) {
      setOptionList([]);
      setDataList([]);
    } else {
      if (selectType === "Covisitor") {
        fetchCovisitor();
      } else if (selectType === "Returnable Item") {
        fetchReturnableItem();
      } else fetchVehicle();
    }
    setShowModal(!showModal);
  };

  const handleSelectChange = (name, value, _) => {
    const covisitor = dataList.find((item) => item.id === value);
    setSelectedOption(covisitor);
  };

  const handleSubmit = () => {
    handleAfterSelect(selectedOption);
    toggleModal();
  };

  return (
    <>
      <span
        className="c-pointer text-primary rounded border p-2 border-primary"
        onClick={toggleModal}
      >
        Select Existing {selectType}{" "}
        <i className="fa-solid fa-chevron-down ms-2"></i>
      </span>
      <Modal show={showModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select Exsting {selectType}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <StaticSelectInput
                isLoading={loading}
                parentClassName="mb-0"
                optionsList={optionList.filter(
                  (item) => !excludeId.includes(item.value)
                )}
                setParentInputValue={handleSelectChange}
                name={"existing_data"}
                label={`Select ${selectType}`}
                labelHint={false}
                id={"existing_data"}
                placeholder={`Select ${selectType}`}
              />
            </div>

            <div className="d-flex align-items-center justify-content-center mt-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
                onClick={handleSubmit}
              >
                Add {selectType}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger ms-3"
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChooseExistingDataModal;
