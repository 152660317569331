import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { updateGatepassApi } from "../../../../services/GatepassService";
import { getSelectOptionList } from "../../../../services/SearchService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import AddGatepassValidationSchema from "./AddGatepassSchema";
import CovisitorSubForm from "./CovisitorSubForm";
import ReturnableItemSubForm from "./ReturnableItemSubForm";
import VehicleSubForm from "./VehicleSubForm";
import VisitingDepartmentSubForm from "./VisitingDepartmentSubForm";
//import Flatpickr from "react-flatpickr";
//import "flatpickr/dist/themes/material_blue.css";

const GatepassEditForm = ({ data }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [departemntOptions, setDepartmentOptions] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      visiting_start_date: data.visiting_start_date,
      visiting_end_date: data.visiting_end_date,
      visitingDepartment: data.approval.map((item) => {
        return { department: item.department.id, officer: item.officer.id };
      }),
      vehicle_available: data.vehicle !== null ? "YES" : "NO",
      vehicle_id: data.vehicle?.id || "",
      vehicle_number: data.vehicle?.vehicle_number || "",
      driver_dl_number: data.vehicle?.driving_licence_number || "",
      drive_type: data.vehicle?.is_self_drive ? "Self" : "Other",
      driver_name: data.vehicle?.driver_name || "",
      driver_aadhaar_number: data.vehicle?.aadhaar_number || "",
      driver_profile_photo: data.vehicle?.profile_photo || null,
      driver_aadhaar_photo: data.vehicle?.aadhaar_photo || null,
      coVisitors: data.covisitors.map((item) => {
        const { id, ...rest } = item;
        return { covisitor_id: id, ...rest };
      }),
      returnableItems: data.returnble_items.map((item) => {
        return {
          item_id: item.id,
          item_name: item.item_name,
          item_description: item.description,
          item_photo: item.item_photo,
        };
      }),
      purpose_of_visiting: data.purpose_of_visiting,
    },
    resolver: yupResolver(AddGatepassValidationSchema),
  });

  async function fetchDepartment() {
    try {
      const resp = await getSelectOptionList("search/visiting_department");
      if (resp) {
        setDepartmentOptions(resp.data.data);
      }
    } catch (erorr) {}
  }

  useEffect(() => {
    fetchDepartment();
  }, []);

  const onSubmit = async (formInput) => {
    console.log("data", formInput);
    const formData = new FormData();

    const appendArrayToFormData = (arr) => {
      arr.forEach((item, index) => {
        Object.keys(item).forEach((subKey) => {
          const formKey = `${subKey}_${index}`;
          formData.append(formKey, item[subKey]);
        });
      });
    };
    const gatepass_id = data.id
    formData.append("id", gatepass_id);
    formData.append("visiting_start_date", formInput.visiting_start_date);
    formData.append("visiting_end_date", formInput.visiting_end_date);
    formData.append("vehicle_available", formInput.vehicle_available);
    if (formInput.vehicle_available === "YES") {
      formData.append("vehicle_id", formInput.vehicle_id);
      formData.append("vehicle_number", formInput.vehicle_number.toUpperCase());
      formData.append("driver_dl_number", formInput.driver_dl_number.toUpperCase());
      formData.append("drive_type", formInput.drive_type);
      if (formInput.drive_type === "Other") {
        formData.append("driver_name", formInput.driver_name);
        formData.append("driver_aadhaar_number", formInput.driver_aadhaar_number);
        formData.append("driver_profile_photo", formInput.driver_profile_photo);
        formData.append("driver_aadhaar_photo", formInput.driver_aadhaar_photo);
      }
    }
    appendArrayToFormData(formInput.visitingDepartment);
    appendArrayToFormData(formInput.coVisitors);
    appendArrayToFormData(formInput.returnableItems);
    formData.append("purpose_of_visiting", formInput.purpose_of_visiting);
    try {
      setSubmitLoading(true);
      const resp = await updateGatepassApi(formData);
      console.log("sss", resp.data)
      toast.success(resp.data.message);
      navigate(`/gatepass/${gatepass_id}`);
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error creating gate");
    } finally {
      setSubmitLoading(false);
    }
  };

  //const today = new Date().toISOString().split('T')[0];

  // const disableEndDates = (date) => {
  //   const { visiting_start_date } = control._formValues; // Access current form values
  //   if (visiting_start_date) {
  //     const startDate = new Date(visiting_start_date);
  //     const maxEndDate = new Date(startDate);
  //     maxEndDate.setDate(startDate.getDate() + 1);
  //     return date < startDate || date > maxEndDate; // Disable past dates and dates beyond max end date
  //   }
  //   return date < new Date(today); // Disable past dates if start date is not set
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Gatepass Information */}
      <div className="row mt-4">
        <div className="col-md-6 row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label">Visiting Start Date</label>
              <input
                type="date"
                className="form-control"
                {...register("visiting_start_date")}
              />
              {errors.visiting_start_date && (
                <p className="text-danger">
                  {errors.visiting_start_date?.message}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label">Visiting End Date</label>
              <input
                type="date"
                className="form-control"
                {...register("visiting_end_date", {
                  required: "End date is required",
                })}
              />
              {errors.visiting_end_date && (
                <p className="text-danger">
                  {errors.visiting_end_date?.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row mt-4">
        <div className="col-md-6 row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label">Visiting Start Date</label>
              <Controller
                name="visiting_start_date"
                control={control}
                render={({ field }) => (
                  <Flatpickr
                    {...field}
                    ref={field.ref}
                    onChange={(date) => field.onChange(date[0])} // Handle date change
                    options={{
                      dateFormat: "Y-m-d",
                      minDate: today, // Disable past dates
                      allowInput: true, 
                    }}
                    placeholder="Select start date"
                    className="form-control"
                  />
                )}
              />
              {errors.visiting_start_date && (
                <p className="text-danger">
                  {errors.visiting_start_date.message}
                </p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="form-label">Visiting End Date</label>
              <Controller
                name="visiting_end_date"
                control={control}
                readOnly={false}
                render={({ field }) => (
                  <Flatpickr
                    {...field}
                    ref={field.ref}
                    onChange={(date) => field.onChange(date[0])} // Handle date change
                    options={{
                      dateFormat: "Y-m-d",
                      minDate: today, // Disable past dates
                      disable: [disableEndDates], // Disable according to start date
                    }}
                    placeholder="Select end date"
                    className={`form-control ${
                      errors.visiting_end_date ? "input-error" : ""
                    }`}
                  />
                )}
              />
              {errors.visiting_end_date && (
                <p className="text-danger">
                  {errors.visiting_end_date.message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* visiting Department */}
      <VisitingDepartmentSubForm
        vdData={data?.approval || []}
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        watch={watch}
        departemntOptions={departemntOptions}
      />

      {/* vehicle info */}
      <VehicleSubForm
        vehicle={data?.vehicle || null}
        register={register}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        watch={watch}
      />

      <CovisitorSubForm
        covData={data?.covisitors || []}
        control={control}
        register={register}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        watch={watch}
      />

      {/* Returnable Items */}

      <ReturnableItemSubForm
        riData={data?.returnble_items || []}
        control={control}
        register={register}
        setValue={setValue}
        clearErrors={clearErrors}
        errors={errors}
        watch={watch}
      />

      <div className="form-group my-3">
        <label className="form-label">Purpose of Visiting</label>
        <textarea
          className="form-control"
          placeholder="Enter purpose of visiting"
          {...register("purpose_of_visiting")}
        />
        {errors.purpose_of_visiting && (
          <p className="text-danger">{errors.purpose_of_visiting?.message}</p>
        )}
      </div>
      <button
        type="submit"
        disabled={submitLoading}
        className="btn btn-primary "
      >
        {submitLoading ? (
          <>
            <Spinner size="sm" /> Submiting... Gatepass
          </>
        ) : (
          "Submit Gatepass"
        )}
      </button>
    </form>
  );
};

export default GatepassEditForm;
