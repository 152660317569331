const DataLoader = () => {
    return (
        <div style={{ height: '5rem' }}>
            <div className="loading-section">
                <div className='sk-wave'>
                    <div className='sk-rect sk-rect-1'></div>
                    <div className='sk-rect sk-rect-2'></div>
                    <div className='sk-rect sk-rect-3'></div>
                    <div className='sk-rect sk-rect-4'></div>
                    <div className='sk-rect sk-rect-5'></div>
                </div>
            </div>
        </div>
    )
}
export default DataLoader
