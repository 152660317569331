import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoWhite from "../../images/logo/logo.png";
import logo from "../../images/logo/logo_text.png";
import { toast } from "react-toastify";
import Captcha from "../constant/CaptchaInput";
import PasswordInput from "../constant/PasswordInput";
import { forgotPasswordReset } from "../../services/AuthService";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const id = queryParams.get('id');

  const [values, setValues] = useState({
    new_password: '',
    confirmPassword: '',
    captchaValue: '',
  });
  const [errors, setErrors] = useState({
    new_password: '',
    confirmPassword: '',
    captcha: '',
  });
  const [captcha, setCaptcha] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(10 * 60); 

  useEffect(() => {
    if (token && id) {
      console.log("Token and ID:", token, id);
    } else {
      navigate("/"); 
    }
  }, [token, id, navigate]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown); 
    } else {
      navigate("/"); 
    }
  }, [timer, navigate]);

  const handleChange = (name, value) => {
    setErrors({ ...errors, [name]: "" });
    setValues({ ...values, [name]: value });
  };

  const handleCaptchaGenerate = (captchaCode) => {
    setCaptcha(captchaCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (!values.new_password) {
      validationErrors.new_password = "New password is required";
    }
    if (values.new_password !== values.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }
    if (values.captchaValue !== captcha) {
      validationErrors.captcha = "Captcha does not match";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        user_id: id,
        token,
        password: values.new_password,
      };
      const resp = await forgotPasswordReset(payload);
      toast.success(resp.data.message);
      navigate("/");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={LogoWhite}
                          alt="Logo"
                          className="logo-img"
                          style={{ marginRight: "0.6rem" }}
                        />
                        <img
                          src={logo}
                          alt="Logo Text"
                          className="text-logo-img light-logo"
                        />
                      </div>
                    </div>
                    <form className="dz-form pb-3" onSubmit={handleSubmit}>
                      <h3 className="form-title text-center m-t0">
                        Reset Password
                      </h3>
                      <div className="dz-separator-outer m-b5">
                        <div className="dz-separator bg-primary style-liner"></div>
                      </div>

                      <PasswordInput
                        onChange={handleChange}
                        errorMessage={errors.new_password}
                        fieldName="new_password"
                        fieldLabel="New Password"
                      />

                      <PasswordInput
                        onChange={handleChange}
                        errorMessage={errors.confirmPassword}
                        fieldName="confirmPassword"
                        fieldLabel="Confirm Password"
                      />

                      <div className="form-group mb-3">
                        <Captcha onGenerate={handleCaptchaGenerate} />
                        <div className="my-3">
                          <label>Captcha</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter captcha code"
                            name="captcha"
                            value={values.captchaValue}
                            onChange={(e) => handleChange("captchaValue", e.target.value)}
                          />
                          {errors.captcha && (
                            <span className="text-danger">
                              {errors.captcha}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={isLoading}
                        >
                          {isLoading ? "Submitting..." : "SUBMIT"}
                        </button>
                      </div>
                    </form>

                    <div className="text-center mt-3">
                      <small>Time left to reset password: {formatTime(timer)}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pb-3">
                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      © Copyright by
                      <a
                        href="https://intelisparkz.com/"
                        rel="noreferrer"
                        target="_blank"
                        className="fs-5 fw-semibold"
                      >
                        {" "}
                        Intelisparkz{" "}
                      </a>
                      All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
