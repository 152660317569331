import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, NavLink } from "react-router-dom";
import Captcha from "../constant/CaptchaInput";
import { signupApi } from "../../services/AuthService";
import { Spinner } from "react-bootstrap";
import VisitorSignupOtpForm from "./RegistrationOtpForm";
import { toast } from "react-toastify";
import PasswordInput from "../constant/PasswordInput";
import bg6 from '../../images/background/home_bg.png';
import logo from '../../images/logo/logo.png';
import logoText from '../../images/logo/logo_text.png';

const SignupSchema = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    phone_number: yup
        .string()
        .required("Phone number is required")
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),

    aadhaar_number: yup
        .string()
        .matches(/^[0-9]{12}$/, "Aadhaar number must be 12 digits")
        .required("Aadhaar number is required"),
    dob: yup
        .string()
        .required("Date of Birth is required")
        .matches(
            /^\d{4}-\d{2}-\d{2}$/,
            "Date of Birth must be in the format YYYY-MM-DD"
        )
        .test("age", "Visitor must be at least 14 years old", function (value) {
            if (!value) return false;
            const today = new Date();
            const birthDate = new Date(value);
            const age = today.getFullYear() - birthDate.getFullYear();
            return age >= 14;
        }),
    gender: yup
        .string()
        .required("Gender is required"),
    company_name: yup.string().required("Company Name is required"),
    designation: yup.string().required("Designation is required"),
    profile_photo: yup
        .mixed().nullable()
        .required("Profile photo is required")
        .test(
            "fileSize",
            "Profile photo must be less than 1MB",
            (value) => {
                if (!value) return false;
                return value && value?.size <= 1024 * 1024
            }
        )
        .test(
            "fileFormat",
            "Profile photo must be in JPG or PNG format",
            (value) => {
                if (!value) return false;
                return ["image/jpeg", "image/png"].includes(value?.type)
            }
        ),
    aadhaar_photo: yup
        .mixed().nullable()
        .required("Aadhaar photo is required")
        .test(
            "fileSize",
            "Aadhaar photo must be less than 1MB",
            (value) => {
                if (!value) return false;
                return value?.size <= 1024 * 1024
            }
        ).test(
            "fileFormat",
            "Aadhaar photo must be in JPG or PNG format",
            (value) => {
                if (!value) return false;
                return ["image/jpeg", "image/png"].includes(value?.type)
            }
        ),
    password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number"),
    captcha: yup
        .string()
        .required("Please enter captcha code"),
    terms_conditions: yup.boolean()
        .required("You must agree to the terms of service & privacy policy")
        .oneOf([true], "Please agree to the terms of service & privacy policy"),
});

const Register = () => {
    const [captchaValue, setCaptchaValue] = useState('');
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
    const [aadhaarPhotoPreview, setAadhaarPhotoPreview] = useState(null);
    const [otpVisible, setOtpVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verificationField, setVerificationField] = useState([]); // this state is ued for store is email or phone number is not verified
    const { register, handleSubmit, formState: { errors }, clearErrors, getValues, setValue, reset, setError, setFocus } = useForm({
        resolver: yupResolver(SignupSchema),
        defaultValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            aadhaar_number: '',
            dob: '',
            gender: '',
            company_name: '',
            designation: '',
            profile_photo: null,
            aadhaar_photo: null,
            password: '',
            captcha: '',
            terms_conditions: false
        }
    });



    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (loading || otpVisible) {
                event.preventDefault();
                event.returnValue = ""; // Chrome requires returnValue to be set
            }
        };

        if (loading || otpVisible) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        } else {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [loading, otpVisible]);

    const toggleOtpVisible = () => { setOtpVisible(!otpVisible) }


    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfilePhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setValue("profile_photo", file);
            clearErrors("profile_photo")
        }
    };

    const handleAadhaarPhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setAadhaarPhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setValue("aadhaar_photo", file);
            clearErrors("aadhaar_photo")
        }
    };

    const onSubmit = async (data) => {
        if (loading) {
            return false
        }
        if (captchaValue === data.captcha) {
            // Prepare form data for API submission
            const formData = new FormData();
            formData.append("first_name", data.first_name);
            formData.append("last_name", data.last_name);
            formData.append("email", data.email);
            formData.append("phone_number", data.phone_number);
            formData.append("aadhaar_number", data.aadhaar_number);
            formData.append("dob", data.dob);
            formData.append("gender", data.gender);
            formData.append("company_name", data.company_name);
            formData.append("designation", data.designation);
            formData.append("profile_photo", data.profile_photo);
            formData.append("aadhaar_photo", data.aadhaar_photo);
            formData.append("password", data.password);
            try {
                setLoading(true)
                const resp = await signupApi(formData)
                const userId = resp.data.data.id
                setValue('id', userId)
                toggleOtpVisible()
            } catch (error) {
                if (error?.response?.data) {

                    const { field_errors, message, user_id, verification_field } = error.response.data
                    if (verification_field.length > 0) {
                        setVerificationField(verification_field)
                        setValue('id', user_id)
                        toggleOtpVisible()
                        return
                    }

                    if (Object.keys(field_errors).length > 0) {
                        Object.entries(field_errors).forEach(([key, value]) => {
                            setError(key, {
                                type: "manual",
                                message: value,
                            })
                        });
                        const firstErrorField = Object.keys(field_errors)[0];
                        if (firstErrorField) {
                            setFocus(firstErrorField);
                        }

                    } else {
                        toast.error(message)
                    }
                }
            } finally {
                setLoading(false)
            }
        } else {
            setError("captcha", {
                type: "manual",
                message: "Invalid captcha code",
            });
        }
    };

    const onPasswordChange = (name, value) => {
        setValue(name, value);
        SignupSchema.validateAt(name, { [name]: value }).then(() => {
            clearErrors(name);
        })
            .catch((err) => {
                setError(name, {
                    type: "manual",
                    message: err.message,
                });
            });
    };

    const handleOnCaptchaGenerate = (captchaCode) => {
        setCaptchaValue(captchaCode)
        setValue('captcha', '')
        clearErrors("captcha")
    }

    const handleClear = () => {
        reset(); // Reset the form to default values
        setProfilePhotoPreview(null);
        setAadhaarPhotoPreview(null);
    }

    return (
        <div className="page-wraper">
            <div className="browse-job login-style3">
                <div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh" }}>
                    <div className="row gx-0">
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'></div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 vh-100 bg-white d-flex align-items-center justify-content-center">
                            <div className="login-form style-2 flex-fill">
                                <div className="pt-4">
                                    <NavLink to={(loading || otpVisible) ? "#" : "/"} style={{ marginBottom: '.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                        <img src={logo} alt="" width={'50px'} className="width-200 " style={{ marginRight: '0.6rem' }} />
                                        <img src={logoText} alt="" width={'150px'} className="width-200 light-logo" />
                                    </NavLink>
                                </div>
                                <div className="card-body" style={{ maxHeight: "80vh", overflowY: "auto" }}>
                                    <h3 className="text-center">{otpVisible ? "Verify Your Email & Phone" : "Visitor Registration Form"}</h3>
                                    {otpVisible ? <VisitorSignupOtpForm
                                        data={getValues()}
                                        toggleOtpVisible={toggleOtpVisible}
                                        handleClear={handleClear}
                                        verificationField={verificationField}
                                    /> : (
                                        <form onSubmit={handleSubmit(onSubmit)}>

                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">First Name</label>
                                                    <input type="text" placeholder="Enter first name" className="form-control" {...register("first_name")} />
                                                    <p className="text-danger">{errors.first_name?.message}</p>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Last Name</label>
                                                    <input type="text" placeholder="Enter last name" className="form-control" {...register("last_name")} />
                                                    <p className="text-danger">{errors.last_name?.message}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Email</label>
                                                    <input type="email" autoComplete="off" placeholder="Enter email address" className="form-control" {...register("email")} />
                                                    <p className="text-danger">{errors.email?.message}</p>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <input type="text" placeholder="Enter phone number" className="form-control" {...register("phone_number")} />
                                                    <p className="text-danger">{errors.phone_number?.message}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Date of Birth</label>
                                                    <input type="date" className="form-control"
                                                        pattern="\d{4}-\d{2}-\d{2}"
                                                        max={new Date().toISOString().split("T")[0]}  {...register("dob")} />
                                                    <p className="text-danger">{errors.dob?.message}</p>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Gender </label><br />

                                                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="MaleRadio"
                                                            value="Male"
                                                            {...register("gender")}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="MaleRadio"
                                                        >
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="FemaleRadio"
                                                            value="Female"
                                                            {...register("gender")}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="FemaleRadio"
                                                        >
                                                            Female
                                                        </label>
                                                    </div>
                                                    <p className="text-danger">{errors.gender?.message}</p>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Aadhaar Number</label>
                                                    <input type="text" placeholder="Enter aadhaar number" className="form-control" {...register("aadhaar_number")} />
                                                    <p className="text-danger">{errors.aadhaar_number?.message}</p>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <PasswordInput
                                                        onChange={onPasswordChange}
                                                        errorMessage={errors.password?.message || ""}
                                                        autoComplete="new-password"
                                                    />
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Company Name</label>
                                                    <input type="text" placeholder="Enter company name" className="form-control" {...register("company_name")} />
                                                    <p className="text-danger">{errors.company_name?.message}</p>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Designation</label>
                                                    <input type="text" placeholder="Enter designation" className="form-control" {...register("designation")} />
                                                    <p className="text-danger">{errors.designation?.message}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Profile Photo (JPG/PNG)</label>
                                                    <div
                                                        className="file-upload-box"
                                                        onClick={() => document.getElementById("profilePhotoInput").click()}
                                                    >
                                                        {profilePhotoPreview ? (
                                                            <img src={profilePhotoPreview} alt="Profile Preview" />
                                                        ) : (
                                                            <p>Click to upload</p>
                                                        )}
                                                    </div>
                                                    <input
                                                        id="profilePhotoInput"
                                                        type="file"
                                                        className="form-control"
                                                        style={{ display: "none" }}
                                                        accept="image/jpeg, image/png"
                                                        {...register("profile_photo")}
                                                        onChange={handleProfilePhotoChange}
                                                    />
                                                    <p className="text-danger">{errors.profile_photo?.message}</p>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
                                                    <div
                                                        className="file-upload-box"
                                                        onClick={() => document.getElementById("aadhaarPhotoInput").click()}
                                                    >
                                                        {aadhaarPhotoPreview ? (
                                                            <img src={aadhaarPhotoPreview} alt="Aadhaar Preview" />
                                                        ) : (
                                                            <p>Click to upload</p>
                                                        )}
                                                    </div>
                                                    <input
                                                        id="aadhaarPhotoInput"
                                                        type="file"
                                                        className="form-control"
                                                        style={{ display: "none" }}
                                                        accept="image/jpeg, image/png"
                                                        {...register("aadhaar_photo")}
                                                        onChange={handleAadhaarPhotoChange}
                                                    />
                                                    <p className="text-danger">{errors.aadhaar_photo?.message}</p>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <Captcha onGenerate={handleOnCaptchaGenerate} />
                                                <div className="my-3">
                                                    <label className="form-label">Captcha</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        style={{ width: '200px' }}
                                                        placeholder="Enter captcha code"
                                                        {...register("captcha")}
                                                    />
                                                    <p className="text-danger">{errors.captcha?.message}</p>
                                                </div>
                                            </div>
                                            <div className="mb-3 mt-3">
                                                <span className="form-check float-start me-2">
                                                    <input type="checkbox" {...register("terms_conditions")} className="form-check-input mt-0" id="check2" />
                                                    <label className="form-check-label d-unset" htmlFor="check2">I agree to the</label>
                                                </span>
                                                <label><Link to={"#"}>Terms of Service </Link>&amp; <Link to={"#"}>Privacy Policy</Link></label>
                                                <p className="text-danger">{errors.terms_conditions?.message}</p>
                                            </div>
                                            <div className="form-group">
                                                <NavLink to={(loading || otpVisible) ? "#" : "/"} className="btn btn-outline-primary" type="button">
                                                    <i className="fa-solid fa-angle-left me-2"></i> Login</NavLink >
                                                <button type="submit" disabled={loading} className="btn btn-primary float-end w-50">
                                                    {loading ? <><Spinner size="sm" /> Submiting...</> : "Submit"}
                                                </button>
                                            </div>
                                            {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                                        </form>
                                    )}
                                </div>
                                <div className="card-footer">
                                    <div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
                                        <div className="col-lg-12 text-center">
                                            <span> © Copyright by <a href="https://intelisparkz.com/" rel="noreferrer" target="_blank" className="fs-5 fw-semibold"> InteliSparkz </a> All rights reserved.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
