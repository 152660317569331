import GatepassAddForm from "./GatepassForm/AddForm";
const AddGatepassPage = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center">Gatepass Application Form</h3>
              <GatepassAddForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGatepassPage;
