import React, { useState, useRef, useEffect, useCallback } from 'react';
import { searchGatepassApi } from '../../../services/GatepassService';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

const GlobalSearch = () => {
    const [instantInputValue, setInstantInputValue] = useState('');  // Immediate update for the input field
    const [inputValue, setInputValue] = useState('');  // Debounced value for the API call
    const [searchResults, setSearchResults] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const debounceTimer = useRef(null);
    const navigate = useNavigate();

    // Handle outside clicks to close the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setSelectedIndex(-1);
                dropdownRef.current.classList.remove('show-result');
            } else if (dropdownRef.current && inputRef.current && inputRef.current.contains(event.target) && searchResults.length > 0) {
                dropdownRef.current.classList.add('show-result');
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [searchResults]);

    // Keyboard event handling
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === '/') {
                event.preventDefault();
                inputRef.current.focus();
            }
            if (searchResults.length > 0) {
                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    setSelectedIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
                } else if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    setSelectedIndex((prevIndex) => (prevIndex - 1 + searchResults.length) % searchResults.length);
                } else if (event.key === 'Enter' && selectedIndex >= 0) {
                    event.preventDefault();
                    navigate(`/gatepass/${searchResults[selectedIndex].id}`);
                    setSelectedIndex(-1);
                    dropdownRef.current.classList.remove('show-result');
                    inputRef.current.blur();
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [searchResults, selectedIndex, navigate]);

    // Debounced API search function
    const handleSearch = useCallback(() => {
        if (inputValue.length > 0) {
            setIsLoading(true);
            searchGatepassApi(inputValue)
                .then((response) => {
                    const results = response?.data?.data || [];
                    setSearchResults(results);
                    setSelectedIndex(results.length ? 0 : -1);
                    dropdownRef.current?.classList.toggle('show-result', results.length > 0);
                })
                .catch((error) => console.error(error?.response?.data?.message || error))
                .finally(() => setIsLoading(false));
        } else {
            setSearchResults([]);
            dropdownRef.current?.classList.remove('show-result');
        }
    }, [inputValue]);

    // Debounce the API call by updating `inputValue` based on `instantInputValue`
    useEffect(() => {
        if (debounceTimer.current) clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(() => setInputValue(instantInputValue), 300);
        return () => clearTimeout(debounceTimer.current);
    }, [instantInputValue]);

    // Trigger the API search whenever `inputValue` changes
    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const handleInputChange = (event) => {
        setInstantInputValue(event.target.value);
    };

    const handleResultClick = (id) => {
        navigate(`/gatepass/${id}`);
        dropdownRef.current?.classList.remove('show-result');
        inputRef.current?.blur();
    };

    return (
        <div className="search-bar" ref={dropdownRef}>
            <div className="input-group search-area">
                <span className="input-group-text search-icon">
                    <i className="fa-solid fa-magnifying-glass"></i>
                </span>
                <input
                    type="text"
                    className="form-control search-input"
                    placeholder="Gatepass Number"
                    onChange={handleInputChange}
                    value={instantInputValue}
                    ref={inputRef}
                />
                <span className="input-group-text">
                    {instantInputValue.length > 0 ? (
                        isLoading ? (
                            <Spinner size="sm" />
                        ) : (
                            <span onClick={() => setInstantInputValue('')}>
                                <i className="fa-solid fa-xmark c-pointer fa-xl"></i>
                            </span>
                        )
                    ) : (
                        <span className="ctrl-btn">
                            <span className="ctrl-btn-code">Ctrl</span>
                            <span className="ctrl-btn-plus">+</span>
                            <span className="ctrl-btn-code">/</span>
                        </span>
                    )}
                </span>
            </div>
            <div className="search-area-result">
                {searchResults.map((item, index) => (
                    <div
                        key={index}
                        className={`search-area-result-item ${index === selectedIndex ? 'selected' : ''}`}
                        onClick={() => handleResultClick(item.id)}
                    >
                        <span>{item.gatepass_number}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GlobalSearch;
