import React, { useState, useCallback, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import { format } from "date-fns";

const CustomFlatpickrDateRange = ({
  handleOnDateSelect,
  label = "Visiting Date",
  className = "mb-3",
  isSetClear = false,
}) => {
  const [flatpickrDates, setFlatpickrDates] = useState(["", ""]);

  // Clear dates when `isSetClear` changes and both dates are set
  const clearDates = useCallback(() => {
    if (flatpickrDates.every((date) => date)) setFlatpickrDates(["", ""]);
  }, [flatpickrDates]);

  useEffect(() => {
    if (isSetClear) {
      clearDates();
    }
  }, [isSetClear, clearDates]);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? format(start, "dd-MM-yyyy") : "";
    const endDate = end ? format(end, "dd-MM-yyyy") : "";

    setFlatpickrDates([startDate, endDate]);
    handleOnDateSelect(startDate, endDate);
  };

  const resetDateFilter = () => {
    setFlatpickrDates(["", ""]);
    handleOnDateSelect("", "");
  };

  return (
    <div className={className}>
      <label className="form-label">{label}</label>
      <div className="input-group custom-flatpickr-group">
        <Flatpickr
          value={flatpickrDates}
          onChange={handleDateChange}
          options={{ mode: "range", dateFormat: "d-m-Y" }}
          className="form-control custom-flatpickr"
          placeholder="Select Visiting date range"
        />
        {flatpickrDates.every((date) => date) && (
          <span className="input-group-text bg-transparent">
            <i
              className="fas fa-times position-absolute fa-xl cursor-pointer"
              onClick={resetDateFilter}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomFlatpickrDateRange;
