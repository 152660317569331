import React, { useState } from "react";
import { updateGateApi } from "../../../services/ProductService";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const GateEditSchema = yup.object().shape({
  name: yup.string().required("Gate Name is required"),
  id: yup.string().required("Gate Id is required"),
});

const GateEditModal = ({ data, handleAfterUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(GateEditSchema),
    defaultValues: { name: "", id: "" },
  });

  // Use watch to keep track of the gate_type field

  const toggleModal = () => {
    if (showModal) {
      reset();
    } else {
      setValue("name", data.name); // Set the 'name' field
      setValue("id", data.id); // Set the 'id' field
    }
    setShowModal(!showModal);
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const resp = await updateGateApi(data);
      handleAfterUpdate(resp.data.data);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error creating gate");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="btn btn-primary btn-sm ms-1" onClick={toggleModal}>
        Edit Gate
      </button>

      <Modal show={showModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Gate</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label>Gate Name</label>
                <input
                  type="text"
                  placeholder="Enter gate name"
                  className="form-control"
                  {...register("name")}
                />
                <p className="text-danger">{errors.name?.message}</p>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" /> Updating...
                    </>
                  ) : (
                    "Update"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ms-3"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GateEditModal;
