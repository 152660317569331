import React, { useCallback, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import DataLoader from "../Loader";
import { StaticSelectInput } from "../../../constant/SelectInput";
import { getSACISFVisitorCountApi } from "../../../../services/DashboardService";
const SACISFVisitorCountChart = () => {
  const [data, setData] = useState({ dates: [], counts: [] });
  const [intervalType, setIntervalType] = useState("last_week");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getSACISFVisitorCountApi(intervalType);
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [intervalType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleIntervalChange = (name, value, _) => {
    setIntervalType(value);
  };

  const intervalOptions = [
    { label: "Last Week", value: "last_week" },
    { label: "Last Month", value: "last_month" },
    { label: "Last Year", value: "last_year" },
  ];

  const options = {
    chart: {
      height: 300,
      type: "area",
      stacked: false,
      toolbar: {
        show: false,
      },
    },

    // stroke: {
    //     width: [0, 1, 1],
    //     curve: 'straight',
    //     dashArray: [0, 0, 5]
    // },
    legend: {
      fontSize: "13px",
      fontFamily: "poppins",
      labels: {
        colors: "#888888",
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        borderRadius: 6,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },

    xaxis: {
      categories: data.dates,
      type: "Daily",
      labels: {
        style: {
          fontSize: "13px",
          colors: "#888888",
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 4,
      labels: {
        style: {
          fontSize: "13px",
          colors: "#888888",
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-sm-12">
        <div className="card">
          <div className="card-header border-0 pb-0 flex-wrap">
            <h3 className="mb-0">Visiting Logs</h3>
            <div>
              <StaticSelectInput
                setParentInputValue={handleIntervalChange}
                name={"interval_visitor"}
                id={"interval_visitor"}
                optionsList={intervalOptions}
                defaultValue={intervalOptions[0]}
              />
            </div>
          </div>
          <div className="card-body  p-0">
            {isLoading ? (
            <div className="card card-body">
              <DataLoader />
            </div>
            ) : data.counts.length === 0 ? (
            <div>
              <h3 className="text-center py-4">No Data Found ...🤕</h3>
            </div>
            ) : (
            <div id="overiewChart">
              <ReactApexChart
                options={options}
                series={[{ name: "visitor", data: data.counts }]}
                type="area"
                height={300}
              />
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SACISFVisitorCountChart;
