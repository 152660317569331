import React, { useState } from "react";
import {
  approveGatepassApi,
  verifyGatepassApi,
} from "../../../services/GatepassService";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ApproveModal = ({
  user,
  gatepass_id,
  gatepass_number,
  approvalItem,
  handleAfterApproved,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [addToQueue, setAddToQueue] = useState(false);

  const toggleModal = () => {
    if(showModal){
      setRejectedReason("")
      setApprovalStatus("")
    } else {
      setApprovalStatus(approvalItem?.status)
      setRejectedReason(approvalItem.reason || "")
    }
    setShowModal(!showModal);
  };

  const handlestatus = (e) => {
    setApprovalStatus(e.target.value);
  };
  const isButtonClickable = user.role === "Admin" && (user?.department?.id === approvalItem?.department?.id);

  const isAnApprover = user?.id === approvalItem?.officer?.id;

  const handleApprove = async () => {
    if (isSubmiting || !isButtonClickable) {
      return;
    }
    try {
      setIsSubmiting(true);
      const payload = {
        id: approvalItem.id,
        gatepass_id,
        status: approvalStatus,
      };
      if (approvalStatus === "Rejected") {
        payload.rejected_reason = rejectedReason;
      }
      const response = await approveGatepassApi(payload);
      const { message, data } = response.data;
      handleAfterApproved(data);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsSubmiting(false);
    }
  };

  const handleVerify = async () => {
    if (isSubmiting || !isButtonClickable) return;

    try {
      setIsSubmiting(true);
      const payload = {
        id: approvalItem.id,
        gatepass_id,
      };
      const response = await verifyGatepassApi(payload);
      const { message, data } = response.data;
      handleAfterApproved(data); // Callback after verification
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <>
      <span
        className={`badge light border-0 ms-2 mb-1 ${approvalItem.status == "Pending" ? 'badge-warning' : 'badge-danger'} ${
          isButtonClickable ? "cursor-pointer" : "disabled"
        }`}
        onClick={() => isButtonClickable && toggleModal()}
        style={{ fontSize: "0.9rem" }}
      >
        {approvalItem.status}
      </span>
      <Modal
        className="modal fade"
        id="exampleModal3"
        show={showModal}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4>
              <strong>
                {isAnApprover ? "Approve" : "Verify"} Gatepass{" "}
                <span className="text-primary">{gatepass_number || ""}</span>
              </strong>
            </h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>

          <div className="modal-body p-4">
            {isAnApprover ? (
              <div>
                <div className="mb-3">
                  <h5>Are you sure you want to approve this gatepass?</h5>
                  <p>
                    Once approved, this gatepass will be marked as approved, and
                    no further changes can be made.
                  </p>
                </div>

                <div className="form-group mb-3">
                  <label className="form-lable me-4">Status: </label>
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="ApprovedRadio"
                      value="Approved"
                      name="status"
                      onChange={handlestatus}
                      checked={approvalStatus === "Approved"}
                    />
                    <label className="form-check-label" htmlFor="ApprovedRadio">
                      Approved
                    </label>
                  </div>

                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="RejectedRadio"
                      value="Rejected"
                      name="status"
                      onChange={handlestatus}
                      checked={approvalStatus === "Rejected"}
                    />
                    <label className="form-check-label" htmlFor="RejectedRadio">
                      Rejected
                    </label>
                  </div>
                </div>

                {approvalStatus === "Rejected" && (
                  <div className="form-group mb-3">
                    <label htmlFor="form-label">Rejected Reason</label>
                    <textarea
                      className="form-control"
                      name="rejectedReason"
                      onChange={(e) => setRejectedReason(e.target.value)}
                      value={rejectedReason}
                      placeholder="Enter Rejected Reason"
                    ></textarea>
                  </div>
                )}

                <div className="d-flex align-items-center justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-outline-primary btn-sm me-1"
                    onClick={handleApprove}
                    disabled={!isButtonClickable || isSubmiting}
                  >
                    {approvalStatus === "Approved"
                      ? isSubmiting
                        ? "Approving..."
                        : "Approve"
                      : isSubmiting
                      ? "Rejecting..."
                      : "Reject"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger ms-2"
                    onClick={() => toggleModal()}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : approvalItem.verified_by === null ? (
              <div>
                <div className="mb-3">
                  <h5>
                    Are you sure you want to add this gatepass to the approval
                    queue?
                  </h5>
                  <p>
                    Once verified, this gatepass will be forwarded to the
                    approver for further action.
                  </p>
                </div>

                <div class="form-check form-switch mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    checked={addToQueue}
                    onChange={() => setAddToQueue(!addToQueue)}
                    id="flexSwitchCheckDefault"
                  />
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    Add To Approval Queue
                  </label>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={handleVerify}
                    type="button"
                    disabled={!isButtonClickable || isSubmiting || !addToQueue}
                  >
                    {isSubmiting ? "Verifying..." : "Verify"}
                  </button>
                  <button
                    className="btn btn-sm btn-danger ms-2"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h4 className="text-center mb-4">
                This gatepass has already been verified.
                </h4>


                <div className="d-flex align-items-center justify-content-center">
                  <button
                    className="btn btn-sm btn-danger ms-2"
                    type="button"
                    onClick={() => toggleModal()}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ApproveModal);
