import React from "react";
import { connect } from "react-redux";
import ChangePassword from "./ChangePassword";
import TextAvatar from "../../constant/TextAvatar";
import { getInitialChar } from "../../../utils";
import ChangeProfilePhoto from "./ChangeProfilePhoto";

function ProfileDetails({ user }) {
  //   const [activeKey, setActiveKey] = useState("login_activity")
  //   const handleSelect = (eventKey) => setActiveKey(eventKey);

  return (
    <>
    <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
              <div>
              <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h4 className="mb-0 ms-2">Profile</h4>
                </div>
              </div>
              <div className="card-body">
                {/* <EditProfileModal email={user?.email} phone_number={user?.phone_number} designation={user?.designation} /> */}
                <div className="d-flex align-items-center justify-content-center flex-column border-bottom">
                  <div
                    className="border border-primary"
                    style={{
                      position: "relative",
                      width: "150px",
                      height: "150px",
                    }}
                  >
                    {user.profile_photo ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}${user?.profile_photo}`}
                        alt="Profile"
                        style={{
                          height: "150px",
                          width: "150px",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <TextAvatar
                        text={getInitialChar(
                          `${user?.first_name} ${user?.last_name}`
                        )}
                        clsName="text-avatar-square"
                        style={{
                          height: "150px",
                          width: "150px",
                          borderRadius:"0px"
                        }}
                      />
                    )}
                    <ChangeProfilePhoto isPhoto={user.profile_photo} />
                    
               </div>

                  <h3 className="my-2">
                    {user?.first_name} {user?.last_name}
                  </h3>
                </div>
                <ul>
                  <li className="my-3 d-flex">
                    <span className="fw-bold">Full Name: </span>
                    {user?.first_name} {user?.last_name}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Email: </span>
                    {user?.email}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Phone Number: </span>
                    {user?.phone_number}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Designation: </span>
                    {user?.designation?.name}
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Role: </span>
                    <span
                      className={`badge light border-0  ${
                        user?.role === "Admin" ? "badge-primary" : "badge-info"
                      } badge-sm ms-2`}
                    >
                      {user?.role}
                    </span>
                  </li>
                  <li className="my-3">
                    <span className="fw-bold">Status: </span>
                    <span
                      className={`badge light border-0 ${
                        user?.status === "Active"
                          ? "badge-success"
                          : "badge-danger"
                      } badge-sm ms-2`}
                    >
                      {user?.status}
                    </span>
                  </li>

                  <li className="mt-3">
                    <span className="fw-bold">Department: </span>
                    {user?.department?.name}
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="py-2 d-flex align-items-center justify-content-between">
                  <h5 className="mb-md-0">Change Password</h5>
                  <ChangePassword id={user?.id} />
                </div>
              </div>
            </div>
              </div>
          </div>
          <div className="col-md-8">
            <div className="card">
          
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};
export default connect(mapStateToProps)(ProfileDetails);
