import React, { useState, useRef, useEffect } from "react";
import ChooseExistingDataModal from "./ChooseExistingDataModal";
import { useFieldArray } from "react-hook-form";

const CovisitorSubForm = ({
  covData = [],
  control,
  register,
  setValue,
  clearErrors,
  errors,
  watch,
}) => {
  const {
    fields: coVisitors,
    append: appendCoVisitor,
    remove: removeCoVisitor,
  } = useFieldArray({
    control,
    name: "coVisitors",
  });

  const createPhotoPreviews = (data, field) =>
    data.reduce((acc, item, index) => {
      acc[index] = `${process.env.REACT_APP_BASE_URL}${item[field]}`;
      return acc;
    }, {});
  

  const [profilePhotoPreviews, setProfilePhotoPreviews] = useState(
    createPhotoPreviews(covData, "profile_photo")
  );
  const [aadhaarPhotoPreviews, setAadhaarPhotoPreviews] = useState(
    createPhotoPreviews(covData, "aadhaar_photo")
  );

  // Refs for profile and aadhaar photos
  const profilePhotoRefs = useRef([]);
  const aadhaarPhotoRefs = useRef([]);

  const handleProfilePhotoChange = (index, e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhotoPreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleAadhaarPhotoChange = (index, e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAadhaarPhotoPreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  // Scroll to the field when there is an error
  useEffect(() => {
    coVisitors.forEach((_, index) => {
      if (errors.coVisitors?.[index]?.profile_photo) {
        profilePhotoRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
      }
      if (errors.coVisitors?.[index]?.aadhaar_photo) {
        aadhaarPhotoRefs.current[index]?.scrollIntoView({ behavior: "smooth" });
      }
    });
  }, [errors, coVisitors]);

  const handleCovisitorSelect = (data, index) => {
    data.covisitor_id = data.id;
    delete data.id;

    Object.entries(data).forEach(([key, value]) => {
      setValue(`coVisitors.${index}.${key}`, value);
      clearErrors(`coVisitors.${index}.${key}`);
    });
    setProfilePhotoPreviews((prev) => ({
      ...prev,
      [index]: `${process.env.REACT_APP_BASE_URL}${data.profile_photo}`,
    }));
    setAadhaarPhotoPreviews((prev) => ({
      ...prev,
      [index]: `${process.env.REACT_APP_BASE_URL}${data.aadhaar_photo}`,
    }));
  };

  const handleCovisitorClear = (index) => {
    setValue(`coVisitors.${index}.covisitor_id`, "");
    setValue(`coVisitors.${index}.first_name`, "");
    setValue(`coVisitors.${index}.last_name`, "");
    setValue(`coVisitors.${index}.email`, "");
    setValue(`coVisitors.${index}.phone_number`, "");
    setValue(`coVisitors.${index}.dob`, "");
    setValue(`coVisitors.${index}.gender`, "");
    setValue(`coVisitors.${index}.company_name`, "");
    setValue(`coVisitors.${index}.designation`, "");
    setValue(`coVisitors.${index}.aadhaar_number`, "");
    setValue(`coVisitors.${index}.profile_photo`, "");
    setValue(`coVisitors.${index}.aadhaar_photo`, "");
    setProfilePhotoPreviews((prev) => ({
      ...prev,
      [index]: "",
    }));
    setAadhaarPhotoPreviews((prev) => ({
      ...prev,
      [index]: "",
    }));
  };

  const isCovisitorSelect = (index) =>
    watch(`coVisitors.${index}.covisitor_id`)?.startsWith("cov_") || false;

  return (
    <div>
      <div className="d-flex align-items-center py-3">
        <h4 className="mb-0 me-2">Co-visitors</h4>
        {coVisitors.length < 3 && (
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={() => appendCoVisitor({})}
          >
            Add Co-visitor
          </button>
        )}
      </div>

      {/* CoVisitors */}
      <div className="row">
        {coVisitors.map((coVisitor, index) => (
          <div key={index} className="col-md-4 col-sm-12 mb-2">
            <div className="border rounded bg-light-gray">
              <div className="border-bottom p-2">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="mb-0">Co-visitor {index + 1}</h6>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm btn-circle"
                    onClick={() => {
                      const updatedProfilePrev = Object.fromEntries(
                        Object.entries(profilePhotoPreviews).filter(
                          ([key]) => key !== index.toString()
                        )
                      );
                      setProfilePhotoPreviews(updatedProfilePrev);

                      const updatedAadhaarPrev = Object.fromEntries(
                        Object.entries(aadhaarPhotoPreviews).filter(
                          ([key]) => key !== index.toString()
                        )
                      );
                      setAadhaarPhotoPreviews(updatedAadhaarPrev);
                      removeCoVisitor(index);
                    }}
                  >
                    <i className="fa fa-solid fa-x"></i>
                  </button>
                </div>
                {isCovisitorSelect(index) ? (
                  <div className="my-3 text-center">
                    <span
                      className="c-pointer text-danger rounded border p-2 border-danger"
                      onClick={() => handleCovisitorClear(index)}
                    >
                      Clear Existing Covisitor{" "}
                      <i className="fa-solid fa-delete-left ms-2"></i>
                    </span>
                  </div>
                ) : (
                  <div className="my-3 text-center">
                    <ChooseExistingDataModal
                      handleAfterSelect={(data) => {
                        handleCovisitorSelect(data, index);
                      }}
                      selectType="Covisitor"
                      excludeId={watch("coVisitors").map(
                        (item) => item.covisitor_id
                      )}
                    />
                  </div>
                )}
              </div>
              <div className="px-2 pt-2">
                <input
                  type="hidden"
                  {...register(`coVisitors.${index}.covisitor_id`)}
                />
                <div className="form-group mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    className="form-control"
                    placeholder="Enter first name"
                    {...register(`coVisitors.${index}.first_name`)}
                  />
                  {errors.coVisitors?.[index]?.first_name && (
                    <p className="text-danger">
                      {errors.coVisitors[index].first_name?.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    className="form-control"
                    placeholder="Enter last name"
                    {...register(`coVisitors.${index}.last_name`)}
                  />
                  {errors.coVisitors?.[index]?.last_name && (
                    <p className="text-danger">
                      {errors.coVisitors[index].last_name?.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Email</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    {...register(`coVisitors.${index}.email`)}
                  />
                  {errors.coVisitors?.[index]?.email && (
                    <p className="text-danger">
                      {errors.coVisitors[index].email.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    className="form-control"
                    placeholder="Enter phone number"
                    {...register(`coVisitors.${index}.phone_number`)}
                  />
                  {errors.coVisitors?.[index]?.phone_number && (
                    <p className="text-danger">
                      {errors.coVisitors[index].phone_number.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Company Name</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    className="form-control"
                    placeholder="Enter company name"
                    {...register(`coVisitors.${index}.company_name`)}
                  />
                  {errors.coVisitors?.[index]?.company_name && (
                    <p className="text-danger">
                      {errors.coVisitors[index].company_name.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Designation</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    className="form-control"
                    placeholder="Enter designation"
                    {...register(`coVisitors.${index}.designation`)}
                  />
                  {errors.coVisitors?.[index]?.designation && (
                    <p className="text-danger">
                      {errors.coVisitors[index].designation.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Aadhaar Number</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    className="form-control"
                    placeholder="Enter aadhaar number"
                    {...register(`coVisitors.${index}.aadhaar_number`)}
                  />
                  {errors.coVisitors?.[index]?.aadhaar_number && (
                    <p className="text-danger">
                      {errors.coVisitors[index].aadhaar_number.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Date of Birth</label>
                  <input
                    readOnly={isCovisitorSelect(index)}
                    type="date"
                    className="form-control"
                    {...register(`coVisitors.${index}.dob`)}
                  />
                  {errors.coVisitors?.[index]?.dob && (
                    <p className="text-danger">
                      {errors.coVisitors[index].dob.message}
                    </p>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Gender </label>
                  <br />

                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      disabled={isCovisitorSelect(index)}
                      className="form-check-input"
                      type="radio"
                      id={`MaleRadio${index}`}
                      value="Male"
                      {...register(`coVisitors.${index}.gender`)}
                    />
                    <label className="form-label" htmlFor={`MaleRadio${index}`}>
                      Male
                    </label>
                  </div>
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      disabled={isCovisitorSelect(index)}
                      type="radio"
                      className="form-check-input"
                      id={`FemaleRadio${index}`}
                      value="Female"
                      {...register(`coVisitors.${index}.gender`)}
                    />
                    <label
                      className="form-label"
                      htmlFor={`FemaleRadio${index}`}
                    >
                      Female
                    </label>
                  </div>
                  {errors.coVisitors?.[index]?.gender && (
                    <p className="text-danger">
                      {errors.coVisitors[index].gender.message}
                    </p>
                  )}
                </div>
                {/* Profile Photo */}
                <div
                  className="form-group col-md-6 mb-3"
                  ref={(el) => (profilePhotoRefs.current[index] = el)}
                >
                  <label className="form-label">Profile Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    onClick={() => {
                      if (!isCovisitorSelect(index)) {
                        document
                          .getElementById(`profilePhotoInput${index}`)
                          .click();
                      }
                    }}
                  >
                    {profilePhotoPreviews[index] ? (
                      <img
                        src={profilePhotoPreviews[index]}
                        alt="Profile Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={`profilePhotoInput${index}`}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register(`coVisitors.${index}.profile_photo`)}
                    onChange={(e) => handleProfilePhotoChange(index, e)}
                  />
                  {errors.coVisitors?.[index]?.profile_photo && (
                    <p className="text-danger">
                      {errors.coVisitors[index].profile_photo.message}
                    </p>
                  )}
                </div>

                {/* Aadhaar Photo */}
                <div
                  className="form-group col-md-6 mb-3"
                  ref={(el) => (aadhaarPhotoRefs.current[index] = el)}
                >
                  <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    onClick={() => {
                      if (!isCovisitorSelect(index)) {
                        document
                          .getElementById(`aadhaarPhotoInput${index}`)
                          .click();
                      }
                    }}
                  >
                    {aadhaarPhotoPreviews[index] ? (
                      <img
                        src={aadhaarPhotoPreviews[index]}
                        alt="Aadhaar Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={`aadhaarPhotoInput${index}`}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register(`coVisitors.${index}.aadhaar_photo`)}
                    onChange={(e) => handleAadhaarPhotoChange(index, e)}
                  />
                  {errors.coVisitors?.[index]?.aadhaar_photo && (
                    <p className="text-danger">
                      {errors.coVisitors[index].aadhaar_photo.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CovisitorSubForm;
