import GatepassEditForm from "./GatepassForm/EditForm";
const EditGatepassContainer = ({ data, toggleGatepassEdit }) => {
  return (
    <div className="pb-2">
      <div className="card border-0 shadow-sm rounded-3">
        <div className="card-header bg-white">
          <div className="d-flex align-items-start align-items-sm-center flex-sm-row flex-column justify-content-between w-100">
            <div className="d-flex align-items-center mb-sm-0 mb-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-sm btn-circle"
                onClick={() => toggleGatepassEdit()}
              >
                <i className="fa-solid fa-arrow-left"></i>
              </button>
              <h4 className="mb-0 ms-2">
                Edit Gatepass{" "}
                <strong className="text-primary">{data.gatepass_number}</strong>
              </h4>
            </div>
          </div>
        </div>

        <div className="card-body">
          <GatepassEditForm  data={data} />
        </div>
      </div>
    </div>
  );
};

export default EditGatepassContainer;
