import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom'
import { loginConfirmedAction } from '../../store/actions/AuthActions';
import { Spinner } from 'react-bootstrap';
import PasswordInput from '../constant/PasswordInput';
import logo from '../../images/logo/logo_text.png';
import LogoWhite from '../../images/logo/logo.png';
import bg6 from '../../images/background/home_bg.png';
import Captcha from '../constant/CaptchaInput';
import { toast } from 'react-toastify';
import { loginApi } from '../../services/AuthService';
import VisitorSignupOtpForm from "./RegistrationOtpForm";

function Login() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	let errorsObj = { email: '', password: '', captcha: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState('');
	const [captchaValue, setCaptchaValue] = useState('');
	const [captcha, setCaptcha] = useState('');
	const [loading, setLoading] = useState(false);

	const [otpVisible, setOtpVisible] = useState(false);
	const [otpUserData, setOtpUserData] = useState(null);
	const [verificationField, setVerificationField] = useState([]);

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (loading || otpVisible) {
				event.preventDefault();
				event.returnValue = ""; // Chrome requires returnValue to be set
			}
		};

		if (loading || otpVisible) {
			window.addEventListener("beforeunload", handleBeforeUnload);
		} else {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		}
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, [loading, otpVisible]);

	const toggleOtpVisible = () => { setOtpVisible(!otpVisible) }

	const dispatch = useDispatch();

	const handleEmailChange = (e) => {
		const { name, value } = e.target
		setEmail(value)
		setErrors({ ...errors, [name]: "" })
	}

	const handleCaptchaChange = (e) => {
		const { name, value } = e.target
		setCaptcha(value)
		setErrors({ ...errors, [name]: "" })
	}

	const handlePasswordChange = (name, value) => {
		setPassword(value)
		setErrors({ ...errors, [name]: "" })
	}

	const hanldeCaptchaGenerate = (code) => {
		setCaptchaValue(code)
		setErrors({ ...errors, captcha: '' })
		setCaptcha('')
	}

	async function onLogin(e) {
		e.preventDefault();
		if (!loading) {
			let error = false;
			const errorObj = { ...errorsObj };
			if (email === '') {
				errorObj.email = 'Email is required';
				error = true;
			}
			if (password === '') {
				errorObj.password = 'Password is required';
				error = true;
			}
			if (captcha === '') {
				errorObj.captcha = 'Please enter captcha code';
				error = true;
			} else if (captcha !== captchaValue) {
				errorObj.captcha = 'Invalid captcha code';
				error = true;
			}
			setErrors(errorObj);
			if (error) {
				return;
			}
			try {
				setLoading(true)
				const formData = {
					email,
					password
				}
				const resp = await loginApi(formData)
				const { data, token } = resp.data
				dispatch(loginConfirmedAction({ ...data }));
				navigate('/dashboard');
				if (process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE") {
					localStorage.setItem("TOKEN", token);
				}
			} catch (error) {
				if (error?.response?.data) {
					const verification_field = error.response.data?.verification_field || []
					if (verification_field.length > 0) {
						setVerificationField((prevState) => {
							return verification_field;
						});
						setOtpUserData((prevState) => {
							return error.response.data?.user_data || null;
						});
						toggleOtpVisible()
						return
					} else {
						toast.error(error.response.data?.message)
					}
				}
			} finally {
				setLoading(false)
			}
		}
	}
	return (
		<div className="page-wraper">
			<div className="browse-job login-style3">
				<div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh", }}>
					<div className="row gx-0">
						<div className='col-xl-8 col-lg-8 col-md-6 col-sm-12'></div>
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 vh-100 bg-white d-flex align-items-center justify-content-center">
							<div className="login-form style-2">
								<div className="card-body" style={{ maxHeight: '90vh', overflowY: "auto" }}>
									<div style={{ marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
										<img src={LogoWhite} alt="" width={'50px'} className="width-200 " style={{ marginRight: '0.6rem' }} />
										<img src={logo} alt="" width={'150px'} className="width-200 light-logo" />
									</div>

									{(otpVisible && verificationField.length > 0) ? (<>
										<h3 className="text-center">
											{verificationField.length === 2 ? "Verify Your Email & Phone" : "Verify Your Email"}
										</h3>
										<VisitorSignupOtpForm data={otpUserData}
											toggleOtpVisible={toggleOtpVisible}
											handleClear={()=>{console.log("clear")}}
											verificationField={verificationField}
										/>

									</>) : (
										<>
											<form className=" dz-form pb-3" onSubmit={onLogin}>
												<h3 className="form-title text-center m-t0">Login Into Your Account</h3>
												<div className="dz-separator-outer m-b5">
													<div className="dz-separator bg-primary style-liner"></div>
												</div>
												<p>Enter your e-mail address and password to continue. </p>
												<div className="form-group mb-3">
													<label>Email</label>
													<input type="email" className="form-control"
														placeholder='Enter Email'
														name='email'
														value={email} onChange={handleEmailChange} />
													{errors.email && <p className="text-danger">{errors.email}</p>}
												</div>
												<PasswordInput
													onChange={handlePasswordChange}
													errorMessage={errors.password || ""}
												/>
												<div className="form-group mb-3">
													<Captcha onGenerate={hanldeCaptchaGenerate} />
													<div className='my-3'>
														<label>Captcha</label>
														<input type="text" className="form-control"
															placeholder='Enter captcha code'
															name='captcha'
															value={captcha}
															onChange={handleCaptchaChange} />
														{errors.captcha && <p className="text-danger">{errors.captcha}</p>}
													</div>
												</div>

												<button type="submit" disabled={loading} className="btn btn-primary btn-block dz-xs-flex my-3">
													{loading ? <><Spinner size="sm" /> Login</> : "Login"}
												</button>
											</form>

											<div className="d-flex align-items-center justify-content-between bottom">
												<NavLink to="/forgot-password" className="c-pointer mt-3" >
													Forgot Password
												</NavLink>

												<NavLink to="/register" className="btn btn-outline-primary btn-sm">
													Visitor Sign up
												</NavLink>
											</div>
										</>
									)}
								</div>
								<div className="card-footer border-top">
									<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
										<div className="col-lg-12 text-center">
											<span> © Copyright by
												<a href="https://intelisparkz.com/" rel="noreferrer" target="_blank" className="fs-5 fw-semibold"> Intelisparzk </a> All rights reserved.</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >
		</div >
	)
}

export default Login;