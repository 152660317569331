import axiosInstance from './AxiosInstance';
const apiUrl = "api/products"


export function getGateListApi(page, page_size, query) {
    const params = new URLSearchParams({
        page,
        page_size,
        query
    });
    return axiosInstance.get(`api/gate?${params.toString()}`);
}

export function getGateApi(id) {
    return axiosInstance.get(`api/gate/${id}`);
}

export function createGateApi(data) {
    return axiosInstance.post('api/gate', data);
}

export function updateGateApi(data) {
    return axiosInstance.put('api/gate', data);
}

// device api


export function getDeviceListApi(page, page_size, query) {
    return axiosInstance.get(`api/device?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getDeviceApi(id) {
    return axiosInstance.get(`api/device/${id}`);
}

export function createDeviceApi(data) {
    return axiosInstance.post('api/device', data);
}

export function updateDeviceApi(data) {
    return axiosInstance.put('api/device', data);
}
