import React, { useState, useEffect, useCallback } from 'react';
import Pagination from "../../constant/Pagination";
import { CancelRequest } from "../../../services/AxiosInstance";
import DebounceSearchInput from "../../constant/DebounceSearchInput";
import { formatDateRange } from "../../../utils";
import TableLoaderEffect from "../../constant/TableLoader";
import { getUserGatepassList } from "../../../services/UserService";
import { Link } from 'react-router-dom';

const UserGatepassList = ({ id, isAnApprover }) => {
    const [gatepassData, setGatepassData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [total, setTotal] = useState(0);
    const recordsPage = 25;  

    const fetchGatepassList = useCallback(() => {
        setIsLoading(true);
        getUserGatepassList(id, currentPage, recordsPage, searchQuery)
          .then((resp) => {
            setGatepassData(resp.data.data);
            setTotal(resp.data.total);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error.response?.data?.message || "Error fetching data");
            setIsLoading(false);
          });
      }, [currentPage, searchQuery]);

      useEffect(() => {
        fetchGatepassList();
      }, [currentPage, searchQuery]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
      };
    
      const handleSearchChange = (value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
        setCurrentPage(1);
      };
  return (
    <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                    <h4 className="heading mb-0">{isAnApprover ? "Requested " : "Verified " }Gatepass List</h4>
                    <div className="d-flex">
                      <div className="form-group me-3">
                        <DebounceSearchInput
                          handleSearchChange={handleSearchChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="employees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Gatepass No.</th>
                          <th>Visitor</th>
                          <th>Visiting Date</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <TableLoaderEffect colLength={6} />
                        ) : (
                          gatepassData?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Link to={`/gatepass/${item.id}`} className='fw-bold'>{item?.gatepass_number}</Link> 
                              </td>
                              <td>
                              <div
                                className="d-flex flex-column"
                              >
                                <Link to={`/visitor/${item?.visitor?.id}`} className='fw-bold'>{item.visitor?.name}</Link>
                                <span>{item.visitor?.email}</span>
                              </div>
                            </td>
                              <td>{formatDateRange(item.visiting_start_date, item.visiting_end_date)}</td>
                              <td>
                                {item.status === "Approved" ? (
                                  <span className="badge light border-0 badge-success badge-sm">
                                    Approved
                                  </span>
                                ) : item.status === "Pending" ? (
                                  <span className="badge light border-0 badge-warning badge-sm">
                                    Pending
                                  </span>
                                ) : (
                                  <span className="badge light border-0 badge-danger badge-sm">
                                    Rejected
                                  </span>
                                )}
                              </td>
                              <td>{item.created_at}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    {!isLoading && (
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
  )
}

export default UserGatepassList