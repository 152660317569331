import React, { useEffect, useRef } from "react";

const Captcha = ({ onGenerate }) => {
  const canvasRef = useRef(null);
  // Generate random CAPTCHA code
  const generateCaptcha = () => {
    const captchaLength = 6;
    const characters =
      "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
    let captchaCode = "";

    for (let i = 0; i < captchaLength; i++) {
      captchaCode += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    onGenerate(captchaCode);
    drawCaptcha(captchaCode);
  };

  // Draw CAPTCHA on canvas
  const drawCaptcha = (captchaCode) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background color
    ctx.fillStyle = "#f0f0f0";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Noise lines
    for (let i = 0; i < 5; i++) {
      ctx.strokeStyle = "#514f4f";
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.stroke();
    }

    // CAPTCHA text
    // ctx.font = "30px Arial";
    ctx.font = "30px sans-serif";
    ctx.fillStyle = getRandomColor();
    ctx.fillText(captchaCode, 35, 35);
  };

  // Random color generator
  const getRandomColor = () => {
    const colorList = [
      "#333333", // Dark Gray
      "#0066cc", // Strong Blue
      "#d9534f", // Soft Red
      "#5cb85c", // Green
      "#f0ad4e", // Amber
      "#337ab7", // Deep Blue
      "#ff5733", // Burnt Orange
      "#8e44ad", // Purple
      "#2c3e50", // Dark Slate
      "#e74c3c", // Strong Red
    ];

    const randomIndex = Math.floor(Math.random() * colorList.length);
    return colorList[randomIndex];
  };

  // Generate CAPTCHA on component mount
  useEffect(() => {
    generateCaptcha();
  }, []);

  return (
    <div className="captcha-container">
      <div className="d-flex align-items-center">
        <canvas
          ref={canvasRef}
          style={{
            backgroundColor: "#f0f0f0",
            border: "1px solid #ccc",
            display: "block",
            borderRadius: "5px",
          }}
          width="200"
          height="50"
        ></canvas>
        <span
          className="btn btn-sm btn-outline-primary btn-circle ms-3"
          onClick={generateCaptcha}
        >
          <i className="fa-solid fa-rotate"></i>
        </span>
      </div>
    </div>
  );
};

export default Captcha;
