import React, {useState, useEffect, useCallback} from "react";
import ReactApexChart from "react-apexcharts";
import DataLoader from "../Loader";

import { getVisitorMostVisitingApi } from "../../../../services/DashboardService";

export const MostVisitDepartmentPieChart = ({ data }) => {
  const options = {
    chart: {
      width: "100%",
      type: "polarArea",
      height: 300,
    },
    labels: data ? data?.map((item) => item.department_name) : [],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -5,
        },
      },
    },

    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name];
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className="card">
      <div className="card-header border-0 pb-0 flex-wrap">
        <h4 className="heading mb-0">Most Visiting Department</h4>
      </div>
      <div className="card-body  p-0">
        {data.length ? (
          <div id="overiewChart">
            <ReactApexChart
              options={options}
              series={data?.map((item) => item.visit_count)}
              type="polarArea"
              height={300}
            />
          </div>
        ) : (
          <DataLoader />
        )}
      </div>
    </div>
  );
};

const VisitorMostVisitCardWidget = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getVisitorMostVisitingApi();
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoading ? (
    <div>
      <h3>Loading...</h3>
    </div>
  ) : data === null ? (
    <div>
      <h3>Something went wrong ...🤕</h3>
    </div>
  ) : (
    <div className="row">
      <div className="col-xl-8 col-sm-6">
        <div className="card">
          <div className="card-body p-0 pb-4">
            <div className="table-responsive active-projects shorting">
              <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                <h4 className="heading mb-0">Most Officer To Meet</h4>
              </div>

              <div
                id="projects-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="projects-tbl"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Meet Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.top_officers.map((item, index) => (
                      <tr key={index}>
                        <td>
                            {item.first_name}
                            {item.last_name}
                        </td>
                        <td>{item.email}</td>
                        <td>
                          <span className="fw-bold">{item.approval_count}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-4 col-sm-6">
        <MostVisitDepartmentPieChart data={data.top_departments} />
      </div>
    </div>
  );
};

export default VisitorMostVisitCardWidget;
