import axiosInstance from "./AxiosInstance";

// export function getBookingAmountGraph(time_period) {
//     return axiosInstance.get(
//       `api/booking_amount_graph?time_period=${time_period}`
//     );
//   }
//   export function getMostRoomBookingGraph(time_period) {
//     return axiosInstance.get(
//       `api/most_booking_room?time_period=${time_period}`
//     );
//   }
//   export function getRoomTypeGraph(time_period) {
//     return axiosInstance.get(
//       `api/booking_room_type_graph?time_period=${time_period}`
//     );
//   }

//   export function getCurrentBookings() {
//     return axiosInstance.get(
//       "api/current_booking"
//     );
//   }

export function getofficerMostVisitor() {
  return axiosInstance.get("api/officer_most_visitor");
}
export function getHighestVisitorsDay(time_period) {
  return axiosInstance.get(
    `api/highest_visitors_day?time_period=${time_period}`
  );
}
export function getVisitorGateComparision(time_period) {
  return axiosInstance.get(
    `api/visitor_gate_comparision?time_period=${time_period}`
  );
}

export function getVisitorStatus() {
  return axiosInstance.get("api/visitor_status");
}


export function getVisitorGatepassCountApi() {
  return axiosInstance.get("api/dashboard/visitor_gatepass_count");
}

export function getVisitorMostVisitingApi() {
  return axiosInstance.get("api/dashboard/visitor_most_visiting");
}


export function getAdminGatepassCountApi() {
  return axiosInstance.get("api/dashboard/admin_gatepass_count");
}

export function getAdminFrequentVisitorApi() {
  return axiosInstance.get("api/dashboard/admin_frequent_visitor");
}


export function getSACISFGatepassCountApi() {
  return axiosInstance.get("api/dashboard/sacisf_gatepass_count");
}

export function getSACISFVisitorCountApi(interval) {
  return axiosInstance.get(`api/dashboard/sacisf_visitor_count?interval=${interval}`);
}

export function getSACISFMostVisitngCountApi() {
  return axiosInstance.get('api/dashboard/sacisf_most_visiting');
}