import React, { useState, useEffect } from "react";
import GlobalSearch from "./GlobalSearch";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Header = ({ userRole }) => {
	const [headerFix, setheaderFix] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

	return (
		<div className={`header ${headerFix ? "is-fixed" : ""}`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div>
							{userRole === "Visitor" &&
								<Link to={"/apply-gatepass"} className="btn btn-sm btn-outline-primary d-sm-block d-none">Apply Gatepass</Link>
							}
						</div>
						<div className="header-left">
							<GlobalSearch />
						</div>
						<ul className="navbar-nav header-right">

						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userRole: state.auth.user?.role || "Visitor"
	};
};

export default connect(mapStateToProps)(Header);
