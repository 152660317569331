import React, { useState, useEffect, useCallback } from "react";
import { getReturnableItemListApi } from "../../../services/UserService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from "../../constant/TableLoader";
import DebounceSearchInput from "../../constant/DebounceSearchInput";
import FullscreenImage from "../../constant/FullscreenImage";

const ReturnableItems = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const recordsPage = 25;

  const navigate = useNavigate();

  const fetchData = useCallback((page, query) => {
    setIsLoading(true);
    getReturnableItemListApi(page, recordsPage, query)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response?.data?.message || "Error fetching data");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setSearchQuery(value);
    setCurrentPage(1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-header">
                <h3 className="mb-0">Returnable-Items List</h3>
                <div className="table-left-header">
                  <div className="form-group me-3">
                    <DebounceSearchInput
                      handleSearchChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Description</th>
                      <th>Photo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoaderEffect colLength={9} />
                    ) : (
                      data.map((itemreturn, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-primary fw-bold c-pointer"
                              onClick={() => navigate(`/gatepass/${itemreturn?.id}`)}
                            >
                              {itemreturn?.item_name}
                            </span>
                          </td>
                          <td>{itemreturn?.description}</td>
                          <td>
                            <FullscreenImage src={`${process.env.REACT_APP_BASE_URL}${itemreturn?.item_photo}`} alt="Item Image" thumbnailStyle={{
                              height: "30px",
                              width: "30px",
                              objectFit: "cover",
                            }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {isLoading ? (
                <div style={{ height: "4rem" }}></div>
              ) : (
                <Pagination
                  currentPage={currentPage}
                  recordsPage={recordsPage}
                  dataLength={total}
                  handlePagination={handlePagination}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnableItems;
