import React, { useState } from "react";
import { createUserApi } from "../../../services/UserService";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DynamicSelectInput from "../../constant/SelectInput";
import PasswordInput from "../../constant/PasswordInput";

const UserAddSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number"),
  department_id: yup.string().required("Department is required"),
  designation_id: yup.string().required("Designation is required"),
  is_hod: yup.boolean(),
  is_an_approver: yup.boolean(),
});

const UserAddModal = ({ handleAfterCreate }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(UserAddSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      department_id: "",
      designation_id: "",
      is_hod: "",
      is_an_approver: "",
    },
  });

  // Use watch to keep track of the gate_type field

  const toggleModal = () => {
    if (showModal) reset();
    setShowModal(!showModal);
  };

  const handleSelectChange = (name, value, _) => {
    setValue(name, value);
    clearErrors(name)
  };

  const onPasswordChange = (name, value) => {
    setValue(name, value);
    UserAddSchema.validateAt(name, { [name]: value }).then(() => {
      clearErrors(name);
    })
    .catch((err) => {
      setError(name, {
        type: "manual",
        message: err.message,
      });
    });
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const resp = await createUserApi(data);
      handleAfterCreate(resp.data.data);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error creating gate");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="btn btn-primary" onClick={toggleModal}>
        Add User
      </button>

      <Modal show={showModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New User</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    className="form-control"
                    {...register("first_name")}
                  />
                  <p className="text-danger">{errors.first_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    className="form-control"
                    {...register("last_name")}
                  />
                  <p className="text-danger">{errors.last_name?.message}</p>
                </div>
              </div>

              <div className="form-group ">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  autoComplete="off"
                  placeholder="Enter email address"
                  className="form-control"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </div>

              <div className="form-group">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  placeholder="Enter phone number"
                  className="form-control"
                  {...register("phone_number")}
                />
                <p className="text-danger">{errors.phone_number?.message}</p>
              </div>

              <PasswordInput
                onChange={onPasswordChange}
                errorMessage={errors.password?.message || ""}
                autoComplete="new-password"
              />

              <div className="form-group">
                <DynamicSelectInput
                  parentClassName="mb-0"
                  setParentInputValue={handleSelectChange}
                  endpoint_name="search/department"
                  name={"department_id"}
                  label={"Department"}
                  labelHint={false}
                  id={"department_id"}
                  placeholder={"Select Department"}
                  isRequired={true}
                />
                <p className="text-danger">{errors.department_id?.message}</p>
              </div>

              <div className="form-group">
                <DynamicSelectInput
                  parentClassName="mb-0"
                  setParentInputValue={handleSelectChange}
                  endpoint_name="search/designation"
                  name={"designation_id"}
                  label={"Designation"}
                  labelHint={false}
                  id={"designation_id"}
                  placeholder={"Select Designation"}
                  isRequired={true}
                />
                <p className="text-danger">{errors.designation_id?.message}</p>
              </div>

              <div className="form-group">
                <span className="form-check float-start me-2">
                  <input
                    type="checkbox"
                    className="form-check-input mt-0"
                    id="is_hod"
                    {...register("is_hod")}
                  />
                  <label className="form-check-label d-unset" htmlFor="is_hod">
                    User is a HOD
                  </label>
                </span>
                <label>(Optional)</label>
              </div>

              <div className="form-group">
                <span className="form-check float-start me-2">
                  <input
                    type="checkbox"
                    className="form-check-input mt-0"
                    id="is_an_approver"
                    {...register("is_an_approver")}
                  />
                  <label
                    className="form-check-label d-unset"
                    htmlFor="is_an_approver"
                  >
                    User Can Approve Visitor Pass
                  </label>
                </span>
                <label>(Optional)</label>
              </div>

              <div className="d-flex align-items-center justify-content-center mt-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" /> Creating...
                    </>
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ms-3"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserAddModal;
