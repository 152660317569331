import React, { useState, useEffect, useCallback } from "react";
import { getDeviceListApi } from "../../../services/ProductService";
import { CancelRequest } from "../../../services/AxiosInstance";
import Pagination from "../../constant/Pagination";
import DeviceAddModal from "./AddDeviceModal";
import DeviceEditModal from "./EditDeviceModal";
import debounce from 'lodash.debounce';
import TableLoaderEffect from '../../constant/TableLoader';
import DebounceSearchInput from "../../constant/DebounceSearchInput";


const DevicePage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 25;

    const fetchData = useCallback((page, query) => {
        setIsLoading(true);
        getDeviceListApi(page, recordsPage, query)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, fetchData]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = (value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
    };

    const handleAfterCreate = (newData) => {
        setData((prevData) => [newData, ...prevData]);
        setTotal((prevTotal) => prevTotal + 1);
    };

    const handleAfterUpdate = (updatedData) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
        );
    };

    const RenderGateType = ({ gateType }) => {
        switch (gateType) {
            case "Entry":
                return (<span className='badge light border-0 badge-success badge-sm'>Entry</span>)
            case "EntryExit":
                return (<span className='badge light border-0 badge-primary badge-sm'>Entry | Exit</span>)
            default:
                return (<span className='badge light border-0 badge-danger badge-sm'>Exit</span>)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-header">
                                <h4 className="heading mb-0">Device List</h4>
                                <div className='table-left-header'>
                                    <div className='form-group me-3'>
                                        <DebounceSearchInput handleSearchChange={handleSearchChange} />
                                    </div>
                                    <DeviceAddModal handleAfterCreate={handleAfterCreate} />
                                </div>
                            </div>
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Device Name</th>
                                            <th>Gate</th>
                                            <th>Device ID</th>
                                            <th>Device Secret</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? <TableLoaderEffect colLength={5} /> : data.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td><span className="me-2">{item.gate.name}</span>
                                                    <RenderGateType gateType={item.gate.gate_type} />
                                                </td>
                                                <td>{item.device_id}</td>
                                                <td>{item.device_secret}</td>
                                                <td>
                                                    <div>
                                                        <DeviceEditModal data={item} handleAfterUpdate={handleAfterUpdate} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                currentPage={currentPage}
                                recordsPage={recordsPage}
                                dataLength={total}
                                handlePagination={handlePagination}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default DevicePage;