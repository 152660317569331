import React, { useState } from "react";

import VisitingLogReportPage from "./VisitingLogLogTab";
import GatepassReportPage from "./GatepassReportTab";

const ReportPage = () => {
  const [reportType, setReportType] = useState("visiting_log");
  const toggleRepotType = (typeOfReport) => {
    setReportType(typeOfReport)
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-header">
                <div>
                  <button
                    className={`btn ${reportType === "visiting_log" ? "btn-primary" : "btn-outline-primary"}`}
                    onClick={() => toggleRepotType("visiting_log")}
                  >Visiting Log Report</button>

                  <button
                    className={`btn ${reportType === "gatepass" ? "btn-primary" : "btn-outline-primary"} ms-2`}
                    onClick={() => toggleRepotType("gatepass")}
                  >Gatepass Report</button>

                </div>
                <div className="table-left-header">
                  <div className="mt-sm-0 mt-3">
                    <button className="btn btn-sm btn-success">Export to Excel</button>
                  </div>
                </div>
              </div>

              {/* all report type */}

              {reportType === "visiting_log" ? <VisitingLogReportPage /> : <GatepassReportPage />}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
