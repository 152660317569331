import React, { useState } from "react";

const FullscreenImage = ({ src, alt, thumbnailStyle, className }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1); // Initial zoom level

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
    setZoomLevel(1); // Reset zoom when closing
  };

  const stopPropagation = (event) => {
    event.stopPropagation(); // Prevent the click from closing the modal
  };

  const handleZoomIn = (event) => {
    event.stopPropagation(); // Prevent modal closing on zoom click
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.2, 5)); // Max zoom level: 5x
  };

  const handleZoomOut = (event) => {
    event.stopPropagation(); // Prevent modal closing on zoom click
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.2, 1)); // Min zoom level: 1x
  };

  return (
    <>
      <img
        src={src}
        alt={alt}
        style={{ ...thumbnailStyle, cursor: "pointer" }}
        onClick={handleImageClick}
        className={className}
      />

      {isFullScreen && (
        <div className="fullscreen-overlay" onClick={handleCloseFullScreen}>
          <div className="close-button" onClick={handleCloseFullScreen}>
            <i className="fa-solid fa-xmark"></i>
          </div>

          {/* Zoom Controls */}
          <div className="zoom-controls" onClick={stopPropagation}>
            <span className="zoom-in" onClick={handleZoomIn}>
              <i className="fa-solid fa-magnifying-glass-plus"></i>
            </span>
            <span className="zoom-out" onClick={handleZoomOut}>
              <i className="fa-solid fa-magnifying-glass-minus"></i>
            </span>
          </div>

          {/* Fullscreen Image with Zoom */}
          <img
            src={src}
            alt={alt}
            className="fullscreen-image"
            style={{ transform: `scale(${zoomLevel})` }} // Apply zoom level
            onClick={stopPropagation} // Prevent modal closing on image click
          />
        </div>
      )}
    </>
  );
};

export default FullscreenImage;
