import React, { useState, useRef } from "react";
import { createCoVisitorApi } from "../../../services/UserService";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const coVisitorsSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  aadhaar_number: yup
    .string()
    .required("Aadhaar number is required")
    .matches(/^\d{12}$/, "Aadhaar number must be exactly 12 digits"),
  dob: yup
    .string()
    .required("Date of Birth is required")
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date of Birth must be in the format YYYY-MM-DD")
    .test("age", "Visitor must be at least 14 years old", function (value) {
      if (!value) return false;
      const today = new Date();
      const birthDate = new Date(value);
      const age = today.getFullYear() - birthDate.getFullYear();
      return age >= 14;
    }),
  gender: yup.string().required("Select gender"),
  company_name: yup.string().required("Company name is required"),
  designation: yup.string().required("Designation is required"),
  profile_photo: yup
    .mixed()
    .nullable()
    .required("Profile photo is required")
    .test(
      "fileSize",
      "File size must be below 1MB",
      (value) => value && value.size <= 1024 * 1024
    )
    .test(
      "fileFormat",
      "Only JPG/PNG files are allowed",
      (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    ),
  aadhaar_photo: yup
    .mixed()
    .nullable()
    .required("Aadhaar photo is required")
    .test(
      "fileSize",
      "File size must be below 1MB",
      (value) => value && value.size <= 1024 * 1024
    )
    .test(
      "fileFormat",
      "Only JPG/PNG files are allowed",
      (value) => value && ["image/jpeg", "image/png"].includes(value.type)
    ),
});

const CoVisitorAddModal = ({ handleAfterCreate }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [aadhaarPhotoPreview, setAadhaarPhotoPreview] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(coVisitorsSchema),
  });

  const toggleModal = () => {
    if (showModal) {
      reset();  
      setProfilePhotoPreview(null);  
      setAadhaarPhotoPreview(null);  
    }
    setShowModal(!showModal);
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("profile_photo", file);
      clearErrors("profile_photo");
    }
  };

  const handleAadhaarPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAadhaarPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setValue("aadhaar_photo", file);
      clearErrors("aadhaar_photo");
    }
  };

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append("first_name", formData.first_name);
      data.append("last_name", formData.last_name);
      data.append("email", formData.email);
      data.append("phone_number", formData.phone_number);
      data.append("company_name", formData.company_name);
      data.append("designation", formData.designation);
      data.append("aadhaar_number", formData.aadhaar_number);
      data.append("dob", formData.dob);
      data.append("gender", formData.gender);
      data.append("profile_photo", formData.profile_photo);
      data.append("aadhaar_photo", formData.aadhaar_photo); 
      const response = await createCoVisitorApi(data);
      handleAfterCreate(response.data.data);
      toast.success(response.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error?.response?.data?.message || "error");
    } finally {
      setLoading(false);
    }
  };  

  return (
    <>
      <button className="btn btn-sm btn-primary" onClick={toggleModal}>
        Add Co-Visitor
      </button>

      <Modal show={showModal} size="lg" onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Co-Visitor</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    className="form-control"
                    {...register("first_name")}
                  />
                  <p className="text-danger">{errors.first_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    className="form-control"
                    {...register("last_name")}
                  />
                  <p className="text-danger">{errors.last_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    placeholder="Enter email address"
                    className="form-control"
                    {...register("email")}
                  />
                  <p className="text-danger">{errors.email?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter phone number"
                    className="form-control"
                    {...register("phone_number")}
                  />
                  <p className="text-danger">{errors.phone_number?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter company name"
                    {...register("company_name")}
                  />
                  <p className="text-danger">{errors.company_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                <label className="form-label">Designation</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Designation"
                    {...register("designation")}
                  />
                  <p className="text-danger">{errors.designation?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Aadhaar Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter aadhaar number"
                    {...register("aadhaar_number")}
                  />
                  <p className="text-danger">{errors.aadhaar_number?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("dob")}
                  />
                  <p className="text-danger">{errors.dob?.message}</p>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label">Gender</label>
                <div className="d-flex">
                  <div className="form-check me-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Male"
                      {...register("gender")}
                    />
                    <label className="form-check-label">Male</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="Female"
                      {...register("gender")}
                    />
                    <label className="form-check-label">Female</label>
                  </div>
                </div>
                <p className="text-danger">{errors.gender?.message}</p>
              </div>
              <div className="row">
              <div className="form-group col-md-6 mb-3">
                <label className="form-label">Profile Photo (JPG/PNG)</label>
                <div className="file-upload-box" onClick={() => document.getElementById("profilePhotoInput").click()}>
                  {profilePhotoPreview ? (
                    <img src={profilePhotoPreview} alt="Profile Preview" />
                  ) : (
                    <p>Click to upload</p>
                  )}
                </div>
                <input
                  id="profilePhotoInput"
                  type="file"
                  className="d-none"
                  onChange={handleProfilePhotoChange}
                />
                <p className="text-danger">{errors.profile_photo?.message}</p>
              </div>

              <div className="form-group col-md-6 mb-3">
                <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
                <div className="file-upload-box" onClick={() => document.getElementById("aadhaarPhotoInput").click()}>
                  {aadhaarPhotoPreview ? (
                    <img src={aadhaarPhotoPreview} alt="Aadhaar Preview" />
                  ) : (
                    <p>Click to upload</p>
                  )}
                </div>
                <input
                  id="aadhaarPhotoInput"
                  type="file"
                  className="d-none"
                  onChange={handleAadhaarPhotoChange}
                />
                <p className="text-danger">{errors.aadhaar_photo?.message}</p>
              </div>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={toggleModal}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CoVisitorAddModal;
