import React, { useState, useEffect, useCallback } from "react";
import { getSACISFGatepassCountApi } from "../../../../services/DashboardService";
import DataLoader from "../Loader";
import { useNavigate } from "react-router-dom";

const SACISFGatepassCountWidget = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getSACISFGatepassCountApi();
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleGatepassClick = (status) => {
    navigate("/gatepass", {
      state: { gatepass_status: status },
    });
  };

  return isLoading ? (
    <div className="row">
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-3 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
    </div>
  ) : data === null ? (
    <div className="card card-body">
      <h3 className="text-center">Something went wrong ...🤕</h3>
    </div>
  ) : (
    <div className="row">
      <div className="col-xl-3 col-sm-6">
        <div className="card bg-success">
          <div className="card-body">
            <h3 className="text-white text-center">Today Visitor Entry</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.visiting_log_entry_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-danger">
          <div className="card-body">
            <h3 className="text-white text-center">Today Visitor Exit</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.visiting_log_exit_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-success">
          <div className="card-body cursor-pointer"
            onClick={() => handleGatepassClick("Active")}>
            <h3 className="text-white text-center">Active Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.active_gatepasses_count}
            </h1>
          </div>
        </div>
      </div>

      <div className="col-xl-3 col-sm-6">
        <div className="card bg-primary">
          <div className="card-body cursor-pointer"
            onClick={() => navigate("/gatepass")}>
            <h3 className="text-white text-center">Total Gatepass</h3>
            <h1 className="text-white text-center" style={{ fontSize: "4rem" }}>
              {data.gatepasses_count}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SACISFGatepassCountWidget;
