import React from "react";
import { connect } from "react-redux";
import VisitorGatepassCountWidget from "./VisitorDashbaord/GatepassCountCard";
import VisitorMostVisitCardWidget from "./VisitorDashbaord/VisitorMostVisiting";
import AdminGatepassCountWidget from "./AdminDashbaord/AdminGatepassCountCard";
import AdminFrequentVisitorActveGatepass from "./AdminDashbaord/AdminFrequesntVisitor";
import SACISFGatepassCountWidget from "./SACISFDashoard/SACISFGatepassCountCard";
import SACISFVisitorCountChart from "./SACISFDashoard/SACISFVisitorCount";
import SACISFMostVisitingPage from "./SACISFDashoard/SACISFMostVisitiing";
const Home = ({ userRole, isAnApprover }) => {
  return (
    <div className="container-fluid">
      {userRole === "Visitor" ? (
        <div>
          <VisitorGatepassCountWidget />
          <VisitorMostVisitCardWidget />
        </div>
      ) : null}

      {["SuperAdmin", "CISF"].includes(userRole) ? (
        <div>
          <SACISFGatepassCountWidget />
          <SACISFMostVisitingPage />
          <SACISFVisitorCountChart />
        </div>
      ): null}

      {userRole === "Admin" ? (
        <div>
          <AdminGatepassCountWidget isAnApprover={isAnApprover} />
          <AdminFrequentVisitorActveGatepass />
        </div>
      ) : null}

      <div className="row mb-2">
        {/* <div className="col-xl-12">
          {userRole === "Visitor" ? (
            <div className="row mb-2">
          <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
          </div>
           ) : (
            <div className="row mb-2">
          <div className="col-md-3 col-sm-6">
          <VisitorStatus />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
        <div className="col-md-3 col-sm-6">
          <Visitor />
        </div>
          </div>
          )}  
          
          <div className="row mb-2">
          <div className="col-md-4 mb-2">
          <OfficerMostVisitor />
        </div>
        <div className="col-md-8">
          <HighestVisitor />
        </div>
          </div>
          <div className="row">
            <div className="col-12">

          <VisitorGateComparision />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "Visitor",
  isAnApprover: state.auth.user?.role === "Admin" ? state.auth.user.is_an_approver : false
});

export default connect(mapStateToProps)(Home);
