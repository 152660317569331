const TableLoaderEffect = ({ colLength }) => {
    return (
        <tr>
            <td colSpan={colLength}>
                <div style={{ height: '20rem', marginTop: '5rem' }}>
                    <div className='text-center d-flex align-items-center justify-content-center'>
                        <h2 className="loading-text">Loading...</h2>
                        <div className="loading-section">
                            <div className='sk-wave'>
                                <div className='sk-rect sk-rect-1'></div>
                                <div className='sk-rect sk-rect-2'></div>
                                <div className='sk-rect sk-rect-3'></div>
                                <div className='sk-rect sk-rect-4'></div>
                                <div className='sk-rect sk-rect-5'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    )
}
export default TableLoaderEffect
