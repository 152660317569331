import React, { useState } from "react";
import { createCISFApi } from "../../../services/CISFService";
import { Form, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DynamicSelectInput from "../../constant/SelectInput";
import PasswordInput from "../../constant/PasswordInput";

const CISFAddAchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  phone_number: yup
    .string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number"),
  // department_id: yup.string().required("Department is required"),
});

const CISFAddModal = ({ handleAfterCreate }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDepartmentVisible, setIsDepartmentVisible] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(CISFAddAchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      is_assign_department: "",
      department_id: "",
    },
  });


  const toggleModal = () => {
    if (showModal) reset();
    setShowModal(!showModal);
  };

  const handleSelectChange = (name, value, _) => {
    setValue(name, value);
    clearErrors(name)
  };

  const onPasswordChange = (name, value) => {
    setValue(name, value);
    CISFAddAchema.validateAt(name, { [name]: value }).then(() => {
      clearErrors(name);
    })
    .catch((err) => {
      setError(name, {
        type: "manual",
        message: err.message,
      });
    });
  };

  const handleCheckboxChange = (event) => {
    setIsDepartmentVisible(event.target.checked);
    if (!event.target.checked) {
      setValue("department_id", "");
      clearErrors("department_id");
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const resp = await createCISFApi(data);
      handleAfterCreate(resp.data.data);
      toast.success(resp.data.message);
      toggleModal();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error creating gate");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button className="btn btn-primary" onClick={toggleModal}>
        Add CISF
      </button>

      <Modal show={showModal} onHide={toggleModal}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New CISF</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    placeholder="Enter first name"
                    className="form-control"
                    {...register("first_name")}
                  />
                  <p className="text-danger">{errors.first_name?.message}</p>
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Enter last name"
                    className="form-control"
                    {...register("last_name")}
                  />
                  <p className="text-danger">{errors.last_name?.message}</p>
                </div>
              </div>

              <div className="form-group ">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  autoComplete="off"
                  placeholder="Enter email address"
                  className="form-control"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </div>

              <div className="form-group">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  placeholder="Enter phone number"
                  className="form-control"
                  {...register("phone_number")}
                />
                <p className="text-danger">{errors.phone_number?.message}</p>
              </div>

              <PasswordInput
                onChange={onPasswordChange}
                errorMessage={errors.password?.message || ""}
                autoComplete="new-password"
              />

             <div className="form-group my-3">
                <div className="form-check me-2">
                  <input
                    type="checkbox"
                    className="form-check-input mt-0"
                    id="is_assign_department"
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label d-unset"
                    htmlFor="is_assign_department"
                  >
                    Assign Department <span>(optional)</span>
                  </label>
                </div>
              </div>

              {isDepartmentVisible && (
                <div className="form-group">
                  <DynamicSelectInput
                    parentClassName="mb-0"
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department_id"}
                    label={"Department"}
                    labelHint={false}
                    id={"department_id"}
                    placeholder={"Select Department"}
                    isRequired={true}
                  />
                  <p className="text-danger">{errors.department_id?.message}</p>
                </div>
              )}

              <div className="d-flex align-items-center justify-content-center mt-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" /> Creating...
                    </>
                  ) : (
                    "Create"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-danger ms-3"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CISFAddModal;
