import axiosInstance from './AxiosInstance';
const userUrl = "api/cisf"
export function getCISFListApi(page, page_size, query) {
    return axiosInstance.get(`${userUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCISFApi(id) {
    return axiosInstance.get(`${userUrl}/${id}`);
}

export function createCISFApi(data) {
    return axiosInstance.post(userUrl, data);
}

export function updateCISFApi(data) {
    return axiosInstance.put(userUrl, data);
}

