import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import ChooseExistingDataModal from "./ChooseExistingDataModal";
const ReturnableItemSubForm = ({
  riData = [],
  control,
  register,
  setValue,
  clearErrors,
  errors,
  watch,
}) => {

  const createItemPhotoPreviews = (data) =>
    data.reduce((acc, item, index) => {
      acc[index] = `${process.env.REACT_APP_BASE_URL}${item.item_photo}`;
      return acc;
    }, {});

    
  const [itemPhotoPreviews, setItemPhotoPreviews] = useState(createItemPhotoPreviews(riData));

  const {
    fields: returnableItems,
    append: appendReturnableItem,
    remove: removeReturnableItem,
  } = useFieldArray({
    control,
    name: "returnableItems",
  });

  const handleItemPhotoChange = (index, e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setItemPhotoPreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleReturnableItemSelect = (data, index) => {
    setValue(`returnableItems.${index}.item_id`, data.id);
    setValue(`returnableItems.${index}.item_name`, data.item_name);
    setValue(`returnableItems.${index}.item_description`, data.description);
    setValue(`returnableItems.${index}.item_photo`, data.item_photo);

    setItemPhotoPreviews((prev) => ({
      ...prev,
      [index]: `${process.env.REACT_APP_BASE_URL}${data.item_photo}`,
    }));

    clearErrors(`returnableItems.${index}.item_id`);
    clearErrors(`returnableItems.${index}.item_name`);
    clearErrors(`returnableItems.${index}.item_description`);
    clearErrors(`returnableItems.${index}.item_photo`);
  };

  const handleReturnableItemClear = (index) => {
    setValue(`returnableItems.${index}.item_id`, "");
    setValue(`returnableItems.${index}.item_name`, "");
    setValue(`returnableItems.${index}.item_description`, "");
    setValue(`returnableItems.${index}.item_photo`, "");
    setItemPhotoPreviews((prev) => ({ ...prev, [index]: "" }));
  };

  const isReturnableItemSelect = (index) =>
    watch(`returnableItems.${index}.item_id`)?.startsWith("rei_") || false;

  return (
    <div>
      <div className="d-flex align-items-center py-3">
        <h4 className="mb-0 me-2">Returnable Items</h4>
        <button
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={() => appendReturnableItem({})}
        >
          Add Item <i className="fa-regular fa-square-plus fa-beat ms-2"></i>
        </button>
      </div>

      <div className="row">
        {returnableItems.map((item, index) => (
          <div key={item.id} className="col-md-6 col-sm-12 mb-2">
            <div className="border rounded bg-light-gray">
              <div className="border-bottom p-2">
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="mb-0">Item {index + 1}</h6>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm btn-circle"
                    onClick={() => {
                      const updatedItemPrev = Object.fromEntries(
                        Object.entries(itemPhotoPreviews).filter(
                          ([key]) => key !== index.toString()
                        )
                      );
                      setItemPhotoPreviews(updatedItemPrev);
                      removeReturnableItem(index);
                    }}
                  >
                    <i className="fa fa-solid fa-x"></i>
                  </button>
                </div>
                {isReturnableItemSelect(index) ? (
                  <div className="my-3 text-center">
                    <span
                      className="c-pointer text-danger rounded border p-2 border-danger"
                      onClick={() => handleReturnableItemClear(index)}
                    >
                      Clear Existing Returnable Item{" "}
                      <i className="fa-solid fa-delete-left ms-2"></i>
                    </span>
                  </div>
                ) : (
                  <div className="my-3 text-center">
                    <ChooseExistingDataModal
                      handleAfterSelect={(data) => {
                        handleReturnableItemSelect(data, index);
                      }}
                      selectType="Returnable Item"
                      excludeId={watch("returnableItems").map(
                        (item) => item.item_id
                      )}
                    />
                  </div>
                )}
              </div>

              <div className="row px-2 py-2">
                <div className="col-md-6 col-sm-12">
                  <input
                    type="hidden"
                    {...register(`returnableItems.${index}.item_id`)}
                  />
                  <div className="form-group  mb-3">
                    <label className="form-label">Item Name</label>
                    <input
                      className="form-control"
                      placeholder="Enter item name"
                      readOnly={isReturnableItemSelect(index)}
                      {...register(`returnableItems.${index}.item_name`)}
                    />
                    {errors.returnableItems?.[index]?.item_name && (
                      <p className="text-danger">
                        {errors.returnableItems[index].item_name.message}
                      </p>
                    )}
                  </div>

                  <div className="form-group  mb-3">
                    <label className="form-label">Item Description</label>
                    <textarea
                      placeholder="Enter Item details"
                      className="form-control"
                      readOnly={isReturnableItemSelect(index)}
                      {...register(`returnableItems.${index}.item_description`)}
                    ></textarea>
                    {errors.returnableItems?.[index]?.item_description && (
                      <p className="text-danger">
                        {errors.returnableItems[index].item_description.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 mb-3">
                  <label className="form-label">Item Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    onClick={() => {
                      if (!isReturnableItemSelect(index)) {
                        document
                          .getElementById(`itemPhotoInput${index}`)
                          .click();
                      }
                    }}
                  >
                    {itemPhotoPreviews[index] ? (
                      <img src={itemPhotoPreviews[index]} alt="Item Preview" />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={`itemPhotoInput${index}`}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register(`returnableItems.${index}.item_photo`)}
                    onChange={(e) => handleItemPhotoChange(index, e)}
                  />
                  {errors.returnableItems?.[index]?.item_photo && (
                    <p className="text-danger">
                      {errors.returnableItems[index].item_photo.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReturnableItemSubForm;
