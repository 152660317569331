import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Placeholder, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getGatepassApi } from "../../../services/GatepassService";
import ApproveModal from "./ApproveModal";
import "./Gatepass.css";
import FullscreenImage from "../../constant/FullscreenImage";
import EditGatepassContainer from "./EditGatepass";
import {
  calculateAge,
  formatDate,
  formatDateRange,
  formatDateTime,
} from "../../../utils";

const GatepassDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function GatepassDetailsPage({ userRole }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayEdit, setIsDisplayEdit] = useState(false);
  const [activeVisitor, setActiveVisitor] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getGatepassApi(id);
      const resp_data = resp.data.data;
      setData(resp_data);
    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAfterApproved = (updateData) => {
    const approval = data.approval.map((item) => {
      if (updateData.id === item.id) {
        return updateData;
      }
      return item;
    });
    setData({ ...data, approval });
  };

  const toggleGatepassEdit = () => {
    setIsDisplayEdit(!isDisplayEdit);
  };

  return (
    <div className="container-fluid">
        {isLoading ? (
          <GatepassDetailsLoading />
        ) : data !== null ? (
          isDisplayEdit ? (
            <EditGatepassContainer
              data={data}
              toggleGatepassEdit={toggleGatepassEdit}
            />
          ) : (
            <div>
              <div className="card border-0 shadow-sm rounded-3">
                <div className="card-header">
                  <div className="d-flex align-items-start align-items-sm-center flex-sm-row flex-column justify-content-between w-100">
                    
                    <div className="d-flex align-items-center mb-sm-0 mb-3">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm btn-circle"
                        onClick={() => navigate("/gatepass")}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <h4 className="mb-0 ms-2">
                        Gatepass{" "}
                        <strong className="text-primary">
                          {data.gatepass_number}
                        </strong>
                        {data.qrcode &&
                        userRole === "Visitor" &&
                        data.is_any_approved ? (
                          <FullscreenImage
                            src={`${process.env.REACT_APP_BASE_URL}${data?.qrcode}`}
                            alt="QR Image"
                            thumbnailStyle={{
                              height: "40px",
                              width: "50px",
                              objectFit: "contain",
                            }}
                            className="mx-2 bg-white"
                          />
                        ) : null}
                      </h4>
                    </div>

                    <div className="d-flex align-items-start align-items-sm-center flex-sm-row flex-column">
                      <h5 className="mb-sm-0 mb-3 mx-2">
                        Visiting Date:
                        <strong className="ms-2">
                          {formatDateRange(
                            data.visiting_start_date,
                            data.visiting_end_date
                          )}
                        </strong>
                      </h5>
                      {!data.is_any_approved && userRole === "Visitor" ? 
                      <button
                        className="btn btn-outline-primary btn-sm "
                        onClick={toggleGatepassEdit}
                      >
                        Edit Gatepass
                      </button>
                      : null}
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="visitor-card rounded-3 h-100">
                        <h5 className="mb-3 border-bottom p-3">
                          Visitor Information
                        </h5>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-4">
                              <FullscreenImage
                                src={`${process.env.REACT_APP_BASE_URL}${data?.visitor?.user?.profile_photo}`}
                                alt="User Image"
                                thumbnailStyle={{
                                  height: "130px",
                                  width: "130px",
                                  objectFit: "cover",
                                }}
                                className="img-thumbnail bg-white"
                              />
                              <div>
                                <FullscreenImage
                                  src={`${process.env.REACT_APP_BASE_URL}${data?.visitor?.aadhaar_photo}`}
                                  alt="Aadhaar Image"
                                  thumbnailStyle={{
                                    height: "130px",
                                    width: "130px",
                                    objectFit: "cover",
                                  }}
                                  className="my-2 img-thumbnail bg-white"
                                />
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <h5 className="mt-0 mb-2 text-dark">
                                {data?.visitor?.user?.first_name}{" "}
                                {data?.visitor?.user?.last_name}
                              </h5>

                              <ul className="list-unstyled text-smoke mb-2">
                                <li className="d-flex mb-3">
                                  <strong className="me-2">Email:</strong>
                                  <span>{data?.visitor?.user?.email}</span>
                                </li>
                                <li className="d-flex mb-3">
                                  <strong className="me-2">Phone:</strong>
                                  <span>
                                    {data?.visitor?.user?.phone_number}
                                  </span>
                                </li>
                                <li className="d-flex mb-3">
                                  <strong className="me-2">DOB:</strong>
                                  <span>
                                    {formatDate(data.visitor.dob)}
                                    <i className="fw-semibold ms-1">
                                      ({calculateAge(data?.visitor?.dob)} yr)
                                    </i>
                                  </span>
                                </li>

                                <li className="d-flex mb-3">
                                  <strong className="me-2">Gender:</strong>
                                  <span>{data?.visitor.gender}</span>
                                </li>

                                <li className="d-flex mb-3">
                                  <strong className="me-2">
                                    Aadhaar Number:
                                  </strong>
                                  <span>{data?.visitor.aadhaar_number}</span>
                                </li>

                                <li className="d-flex mb-3">
                                  <strong className="me-2">Company:</strong>
                                  <span>{data?.visitor.company_name}</span>
                                </li>

                                <li className="d-flex mb-3">
                                  <strong className="me-2">Designation:</strong>
                                  <span>{data?.visitor.designation}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="gatepass-card rounded-3 h-100">
                        <h5 className="mb-3 border-bottom p-3">
                          Department & Status{" "}
                          {data.is_any_approved && userRole === "Visitor" ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="button-tooltip-2">
                                  Gatepass PDF
                                </Tooltip>
                              }
                            >
                              <a
                                href={`${process.env.REACT_APP_BASE_URL}/api/gatepass_pdf/${data.gatepass_number}`}
                                className="text-danger float-end"
                                rel="noreferrer"
                                target="_blank"
                              >
                                <i className="fa-regular fa-file-pdf fa-lg"></i>
                              </a>
                            </OverlayTrigger>
                          ) : null}
                        </h5>
                        <ul className="list-unstyled">
                          {data.approval.map((item, index) => (
                            <li
                              className="mb-2 px-3 border-bottom pb-3 d-flex align-items-start flex-column"
                              key={index}
                            >
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex flex-column">
                                  <span className="fw-bold fs-5 mb-1">
                                    {item.department.name}
                                  </span>
                                  <span className="fw-normal">
                                    {item.officer.name}{" "}
                                    <span className="fw-semibold">
                                      ({item.officer.designation})
                                    </span>
                                  </span>
                                </div>

                                <div className="d-flex">
                                  {item.verified_by ? (
                                    <div className="mx-2">
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip
                                            id={`verified-tool-tip-${item.id}`}
                                          >
                                            <div className="d-flex flex-column align-items-start">
                                              <span className="fw-bold">
                                                Verified By:
                                              </span>
                                              <span>
                                                {item.verified_by.name}
                                              </span>
                                              <span>
                                                {item.verified_by.email}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        }
                                      >
                                        <i className="fa-regular fa-circle-check text-info fs-3 mb-0"></i>
                                      </OverlayTrigger>
                                    </div>
                                  ) : null}

                                  <div className="d-flex flex-column">
                                    {item?.status === "Approved" ? (
                                      <span className="badge light border-0 badge-success ms-2 mb-1">
                                        Approved
                                      </span>
                                    ) : (
                                      <ApproveModal
                                        gatepass_number={data?.gatepass_number}
                                        gatepass_id={data?.id}
                                        approvalItem={item}
                                        handleAfterApproved={
                                          handleAfterApproved
                                        }
                                      />
                                    )}
                                    <small>
                                      {formatDateTime(item.last_update)}
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {item.status === "Rejected" && item.reason && (
                                  <p className="text-danger p-0 m-0">
                                    <i className="fa-regular fa-circle-question"></i>{" "}
                                    {item.reason}
                                  </p>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3 mb-md-0">
                      <div className="gatepass-card rounded-3 h-100">
                        <h5 className="mb-3 border-bottom p-3">
                          Vehicle's Information
                        </h5>
                        {data.vehicle ? (
                          <ul className="list-unstyled px-3">
                            <li className="mb-3">
                              <span className="fw-bold">Vehicle Number:</span>{" "}
                              {data?.vehicle?.vehicle_number}
                            </li>

                            <li className="mb-3">
                              <span className="fw-bold">Driving License:</span>{" "}
                              {data?.vehicle?.driving_licence_number}
                            </li>
                            {data?.vehicle?.is_self_drive ? (
                              <li className="mb-3">
                                <span className="fw-bold">Drive Type:</span>{" "}
                                Self Drive
                              </li>
                            ) : (
                              <>
                                <li className="mb-3">
                                  <span className="fw-bold">Driver Name:</span>{" "}
                                  {data?.vehicle?.driver_name}
                                </li>
                                <li className="mb-3">
                                  <span className="fw-bold">
                                    Aadhaar Number:
                                  </span>{" "}
                                  {data?.vehicle?.aadhaar_number}
                                </li>
                                <li className="mb-3 d-flex flex-md-row flex column">
                                  <FullscreenImage
                                    src={`${process.env.REACT_APP_BASE_URL}${data?.vehicle?.profile_photo}`}
                                    alt="User Image"
                                    thumbnailStyle={{
                                      height: "130px",
                                      width: "130px",
                                      objectFit: "cover",
                                    }}
                                    className="img-thumbnail bg-white"
                                  />
                                  <FullscreenImage
                                    src={`${process.env.REACT_APP_BASE_URL}${data?.vehicle?.aadhaar_photo}`}
                                    alt="User Image"
                                    thumbnailStyle={{
                                      height: "130px",
                                      width: "130px",
                                      objectFit: "cover",
                                    }}
                                    className="ms-2 img-thumbnail bg-white"
                                  />
                                </li>
                              </>
                            )}
                          </ul>
                        ) : (
                          <h5 className="text-center my-5 text-danger">
                            Vehicle Not Available !
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div className="content">
                  {data?.covisitors.length > 0 && (
                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0 ms-2">Co-Visitors Details</h4>
                      </div>
                      <div className="card-body px-4">
                        <div className="row">
                          {data?.covisitors.map((covisit, index) => (
                            <div className="col-md-4 mb-4" key={covisit?.id}>
                              <div
                                className={`card covisit-card border-0 shadow-sm ${
                                  activeVisitor === index
                                    ? "active-card"
                                    : "inactive-card"
                                }`}
                              >
                                <div className="card-body covisit-card-body p-1">
                                  <div className="row">
                                    <div className="col-5 px-1">
                                      <FullscreenImage
                                        src={`${process.env.REACT_APP_BASE_URL}${covisit?.profile_photo}`}
                                        alt="User Image"
                                        className="img-fluid rounded mb-2"
                                      />
                                      {activeVisitor === index && (
                                        <FullscreenImage
                                          src={`${process.env.REACT_APP_BASE_URL}${covisit?.aadhaar_photo}`}
                                          alt="Aadhaar Image"
                                          className="img-fluid mt-2 rounded"
                                        />
                                      )}
                                    </div>

                                    <div className="col-7 p-3">
                                      <h5 className="mt-0 mb-2 text-dark">
                                        {covisit?.first_name}{" "}
                                        {covisit?.last_name}
                                      </h5>
                                      <ul className="list-unstyled text-smoke mb-2">
                                        <li className="d-flex mb-2">
                                          <i className="mdi mdi-email me-2"></i>
                                          <span>{covisit?.email}</span>
                                        </li>
                                        <li className="d-flex mb-2">
                                          <i className="mdi mdi-phone me-2"></i>
                                          <span>{covisit?.phone_number}</span>
                                        </li>
                                      </ul>
                                      <span
                                        className="text-primary cursor-pointer"
                                        onClick={() =>
                                          setActiveVisitor(
                                            activeVisitor === index
                                              ? null
                                              : index
                                          )
                                        }
                                      >
                                        {activeVisitor === index
                                          ? "Hide Details"
                                          : "View Details"}{" "}
                                        <i className="fa-solid fa-angle-right"></i>
                                      </span>

                                      {activeVisitor === index && (
                                        <div className="mt-3">
                                          <p className="mb-2">
                                            <strong>Date of Birth:</strong>{" "}
                                            {covisit?.dob}{" "}
                                            <strong>
                                              ({calculateAge(covisit?.dob)} yr)
                                            </strong>
                                          </p>
                                          <p className="mb-2">
                                            <strong>Gender:</strong>{" "}
                                            {covisit?.gender}
                                          </p>
                                          <p className="mb-2">
                                            <strong>Designation:</strong>{" "}
                                            {covisit?.designation}
                                          </p>
                                          <p className="mb-2">
                                            <strong>Company:</strong>{" "}
                                            {covisit?.company_name}
                                          </p>
                                          <p>
                                            <strong>Aadhaar:</strong>{" "}
                                            {covisit?.aadhaar_number}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {data?.returnble_items.length > 0 && (
                    <div className="card">
                      <div className="card-header">
                        <h4 className="mb-0 ms-2">Returnable Items</h4>
                      </div>
                      <div className="card-body px-1">
                        <div className="row">
                          {data?.returnble_items.map((item, index) => (
                            <div className="col-md-4 mb-4" key={item.id}>
                              <div className="covisit-card card p-2">
                                <FullscreenImage
                                  src={`${process.env.REACT_APP_BASE_URL}${item?.item_photo}`}
                                  alt="Item Image"
                                  className="img-fluid rounded"
                                />
                                <div className="card-body text-left">
                                  <h5 className="card-title">
                                    {item?.item_name}
                                  </h5>
                                  <p className="card-text">
                                    {item?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="card">
            <div className="card-body">
              <h4 className="text-center text-danger">
                ⚠️ Gatepass Not Found!
                <br /> ID {id}
              </h4>
            </div>
          </div>
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "Visitor",
});

export default connect(mapStateToProps)(GatepassDetailsPage);
