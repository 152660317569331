import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Pagination from "../../../constant/Pagination";
import TableLoaderEffect from "../../../constant/TableLoader";
import { getVisitorVisitingLogApi } from "../../../../services/UserService";
import CustomFlatipckrDateRange from "../../../constant/CustomFlatipckrDateRange";
import DebounceSearchInput from "../../../constant/DebounceSearchInput";
import DynamicSelectInput ,{ StaticSelectInput } from "../../../constant/SelectInput";
import { CancelRequest } from "../../../../services/AxiosInstance";


const VisitorLog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    start_date: "",
    end_date: "",
    status: "",
    query: "",
    department: ""
  });
  const [isClearAll, setIsClearAll] = useState(false);
  const recordsPerPage = 10;
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    // Cancel any ongoing request
    CancelRequest();
    setIsLoading(true);
    try {
      const resp = await getVisitorVisitingLogApi(currentPage, recordsPerPage, id, filter);
      setData(resp.data.data);
      setTotal(resp.data.total);
    } catch (error) {
      console.error(error.response?.data?.message || "Error fetching data");
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleDateChange = (start_date, end_date) => {
    setFilter((prev) => ({
      ...prev,
      start_date,
      end_date
    }));
    setCurrentPage(1);
  };

  const handleSelectChange = (name, value, _) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }));
    setIsClearAll(false);
    setCurrentPage(1);
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = (value) => {
    if (value.length > 0) CancelRequest();
    setFilter((prev) => ({
      ...prev,
      query: value
    }));
    setCurrentPage(1);
  };

  return (
    <div>
      <div className="table-header">
        <h3 className="mb-0">Visiting Logs</h3>
        <div className="table-left-header">
          <div className="form-group">
            <DebounceSearchInput
              handleSearchChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="row my-2 px-3">
        <div className="col-md-4 form-group">
          <StaticSelectInput
            optionsList={[
              { label: "Entry", value: "Entry" },
              { label: "Exit", value: "Exit" },
            ]}
            setParentInputValue={handleSelectChange}
            name="status"
            isClearable={true}
            placeholder="Select Status"
            label={"Status"}
            labelHint={false}
            defaultValue={
              filter.status
                ? { label: filter.status, value: filter.status }
                : null
            }
            id="status"
            isSetEmpty={isClearAll}
          />
        </div>

        <div className="col-md-4 form-group">
          <DynamicSelectInput
            setParentInputValue={handleSelectChange}
            endpoint_name="search/department"
            name={"department"}
            label={"Department"}
            labelHint={false}
            id={"department"}
            isClearable={true}
            placeholder={"Select Department"}
          />
        </div>

        <div className="col-md-4 form-group mb-3">
          <CustomFlatipckrDateRange handleOnDateSelect={handleDateChange} />
        </div>
      </div>

      <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
        <table className="table ItemsCheckboxSec dataTable no-footer mb-0">
          <thead>
            <tr>
              <th>Gatepass No.</th>
              <th>Department</th>
              <th>Gate</th>
              <th>Status</th>
              <th>Datetime</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoaderEffect colLength={5} />
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/visitor/${item.gatepass?.id}`}
                      className="text-primary fw-bold c-pointer"
                    >
                      {item.gatepass?.gatepass_number}
                    </Link>
                  </td>
                  <td>
                    {item.department || ""}
                  </td>
                  <td>{item.gate?.name}</td>
                  <td>
                    {item.status ? (
                      <span className="badge light border-0 badge-success badge-sm">
                        Entry
                      </span>
                    ) : (
                      <span className="badge light border-0 badge-danger badge-sm">
                        Exit
                      </span>
                    )}
                  </td>
                  <td>{item.created_at}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {isLoading ? (
        <div style={{ height: "4rem" }}></div>
      ) : (
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPerPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      )}
    </div>
  );
};

export default VisitorLog;
