import React, { useState, useEffect } from "react";
import TableLoaderEffect from "../../constant/TableLoader";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";

import {
  getApprovalQueueGatepassListApi,
  approveApprovalQueueGatepassApi,
} from "../../../services/GatepassService";
import { formatDateRange } from "../../../utils";

const GatepassApprovalQueuePage = ({ user }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGatepass, setSelectedGatepass] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getApprovalQueueGatepassListApi()
      .then((resp) => {
        setData(resp.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, []);

  const toggleCheckbox = (id) => {
    setSelectedGatepass((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedGatepass.length === data.length) {
      setSelectedGatepass([]);
    } else {
      setSelectedGatepass(data.map((item) => item.id));
    }
  };

  const handleApprove = () => {
    approveApprovalQueueGatepassApi({ approval_id_list: selectedGatepass })
      .then((resp) => {
        setData(data.filter((item) => !selectedGatepass.includes(item.id)));
        setSelectedGatepass([]);
        toast.success(resp.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0 pb-5">
              <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                <div className="d-flex  align-items-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-circle"
                    onClick={() => navigate("/dashboard")}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <h4 className="mb-0 ms-2">Gatepas Approval Queue</h4>
                </div>

                <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                  <span className="badge badge-lg badge-circle badge-outline-info me-3">
                    selected {selectedGatepass.length}
                  </span>
                  {selectedGatepass.length > 0 && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handleApprove}
                    >
                      Approve
                    </button>
                  )}
                </div>
              </div>
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div
                  id="employee-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyees-tblwrapper"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>
                          <div className="form-check custom-checkbox checkbox-primary">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={
                                selectedGatepass.length === data.length &&
                                data.length !== 0
                              }
                              onChange={handleSelectAll}
                            />
                          </div>
                          {/* <label htmlFor="" className="ms-2">All</label> */}
                        </th>
                        <th>Gatepass.</th>
                        <th>Visitor</th>
                        <th>Visiting Date</th>
                        <th>Verified By</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <TableLoaderEffect colLength={6} />
                      ) : data.length > 0 ? (
                        data.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="form-check custom-checkbox checkbox-primary">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedGatepass.includes(item.id)}
                                  onChange={() => toggleCheckbox(item.id)}
                                />
                              </div>
                            </td>

                            <td>
                              <Link
                                to={`/gatepass/${item.gatepass_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.gatepass_number}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={`/visitor/${item.visitor.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.visitor.name}
                              </Link>
                            </td>
                            <td>
                              {formatDateRange(
                                item.visiting_start_date,
                                item.visiting_end_date
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/user/${item.verified_by.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.verified_by.name}
                              </Link>
                            </td>
                            <td>
                              {item.status === "Approved" ? (
                                <span className="badge light border-0 badge-success badge-sm">
                                  Approved
                                </span>
                              ) : item.status === "Pending" ? (
                                <span className="badge light border-0 badge-warning badge-sm">
                                  Pending
                                </span>
                              ) : (
                                <span className="badge light border-0 badge-danger badge-sm">
                                  Rejected
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}><h4 className="text-center">No Record Found</h4></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(GatepassApprovalQueuePage);
