import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LogoWhite from "../../images/logo/logo.png";
import logo from "../../images/logo/logo_text.png";
import { toast } from "react-toastify";
import Captcha from "../constant/CaptchaInput";
import { forgotPassword } from "../../services/AuthService";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '', captcha: '' });
  const [captchaValue, setCaptchaValue] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [loading, setLoading] = useState(false);
  let errorsObj = { email: '', captcha: '' };
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: '' });
  };

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
    setErrors({ ...errors, captcha: '' });
  };

  const handleCaptchaGenerate = (code) => {
    setCaptchaValue(code);
    setErrors({ ...errors, captcha: '' });
    setCaptcha('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
		if (!loading) {
			let error = false;
			const errorObj = { ...errorsObj };
			if (email === '') {
				errorObj.email = 'Email is required';
				error = true;
			}
			if (captcha === '') {
				errorObj.captcha = 'Please enter captcha code';
				error = true;
			} else if (captcha !== captchaValue) {
				errorObj.captcha = 'Invalid captcha code';
				error = true;
			}
			setErrors(errorObj);
			if (error) {
				return;
			}
    try {
      const response = await forgotPassword(email);
      toast.success(response.data.message); 
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data?.message)
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    } finally {
      setLoading(false)
    }
  };
}

  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-4">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={LogoWhite}
                          alt="Logo"
                          className="logo-img"
                          style={{ marginRight: "0.6rem" }}
                        />
                        <img
                          src={logo}
                          alt="Logo Text"
                          className="text-logo-img light-logo"
                        />
                      </div>
                    </div>
                    <form className="dz-form pb-3" onSubmit={handleSubmit}>
                      <h3 className="form-title text-center m-t0">
                        Forgot Password
                      </h3>
                      <div className="dz-separator-outer m-b5">
                        <div className="dz-separator bg-primary style-liner"></div>
                      </div>
                      <p className="text-center">
                        Enter your e-mail address to reset your password
                      </p>
                      <div className="form-group mb-3">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                        {errors.email && (
                          <span className="text-danger">{errors.email}</span>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <Captcha onGenerate={handleCaptchaGenerate} />
                        <div className="my-3">
                          <label>Captcha</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter captcha code"
                            name="captcha"
                            value={captcha}
                            onChange={handleCaptchaChange}
                          />
                          {errors.captcha && (
                            <span className="text-danger">{errors.captcha}</span>
                          )}
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={loading}
                        >
                          {loading ? "Submitting..." : "SUBMIT"}
                        </button>
                      </div>
                    </form>
                    <div className="d-flex align-items-center justify-content-end bottom">
                      <NavLink to="/" className="btn text-primary btn-sm">
                        Back To Login
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="bottom-footer clearfix m-t10 m-b20 row text-center">
                  <div className="col-lg-12 text-center">
                    <span>
                      © Copyright by
                      <a
                        href="https://intelisparkz.com/"
                        rel="noreferrer"
                        target="_blank"
                        className="fs-5 fw-semibold"
                      >
                        {" "}
                        Intelisparzk{" "}
                      </a>{" "}
                      All rights reserved.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
