import React, { useState } from "react";
import OtpInput from "./OTPInput";
import { verifySignupOtpApi, resendOTP } from "../../services/AuthService"; // Import resendOTP
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Timer from "./Timer";
import { maskEmail, maskPhoneNumber } from "../../utils";

const OTP_DURATION = 100;

const VisitorSignupOtpForm = ({ data, toggleOtpVisible, handleClear, verificationField }) => {
  const [otp, setOtp] = useState({ email_otp: "", phone_otp: "" });
  const [loading, setLoading] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [phoneSending, setPhoneSending] = useState(false);
  const [isResendEmailOTP, setIsResendEmailOTP] = useState(false);
  const [isResendPhoneOTP, setIsResendPhoneOTP] = useState(false);
  const navigate = useNavigate();

  const onSubmitOtp = (name, code) => {
    setOtp({ ...otp, [name]: code });
  };


  const handleEmailOtpExpire = () => {
    setIsResendEmailOTP(true);
  };

  const handlePhoneOtpExpire = () => {
    setIsResendPhoneOTP(true);
  };

  const handleResendEmailOtp = async () => {
    if (loading) return; // Prevent multiple clicks
    try {
      setEmailSending(true)
      const resp = await resendOTP({ id: data.id, otp_type: "Email Verification" }); // Call resendOTP API
      toast.success(resp.data.message); // Show success message
      setIsResendEmailOTP(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setEmailSending(false)
    }
  };

  const handleResendPhoneOtp = async () => {
    if (loading) return; // Prevent multiple clicks
    try {
      setPhoneSending(true);
      const resp = await resendOTP({ id: data.id, otp_type: "Phone Verification" }); // Call resendOTP API
      toast.success(resp.data.message); // Show success message
      setIsResendPhoneOTP(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setPhoneSending(false);
    }
  };

  const handleOnVerify = async () => {
    if (loading) {
      return false;
    }
    try {
      setLoading(true);
      const resp = await verifySignupOtpApi({ ...otp, id: data.id });
      const message = resp.data.message;
      toast.success(message);
      setLoading(false);
      handleClear();
      navigate('/');
      toggleOtpVisible();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  };


  const EmailOTPInput = () => {
    return (
      <div className="d-flex flex-column">
        <h5 className="mb-0">Enter OTP for Email:</h5>
        <span>OTP has been sent to your email <strong>{data.email && maskEmail(data.email)}</strong>. Please check your inbox or spam folder.</span>
        <OtpInput name={"email_otp"} length={6} onSubmitOtp={onSubmitOtp} />
        <div className="d-flex justify-content-end align-items-center">

          {isResendEmailOTP ? (
            <span
              className="text-primary cursor-pointer"
              onClick={handleResendEmailOtp}
            >
              {emailSending ? "Resending... Email OTP" : "Resend Email OTP"}
            </span>
          ) : (
            <>
              <Timer duration={OTP_DURATION} onExpire={handleEmailOtpExpire} restartTimer={!isResendEmailOTP} />
              <span className="text-muted">Resend Email OTP</span>
            </>
          )}

        </div>
      </div>
    )
  }

  const PhoneOTPInput = () => {
    return (
      <div className="d-flex flex-column mt-3">
        <h5 className="mb-0">Enter OTP for Phone:</h5>
        <span>OTP has been sent to your phone number <strong>{data.phone_number && maskPhoneNumber(data.phone_number)}</strong>.</span>
        <OtpInput name={"phone_otp"} length={6} onSubmitOtp={onSubmitOtp} />
        <div className="d-flex justify-content-end align-items-center">
          {isResendPhoneOTP ? (
            <span
              className="text-primary cursor-pointer"
              onClick={handleResendPhoneOtp}
            >
              {phoneSending ? "Resending... Phone OTP" : "Resend Phone OTP"}
            </span>
          ) : (
            <>
              <Timer duration={OTP_DURATION} onExpire={handlePhoneOtpExpire} restartTimer={!isResendPhoneOTP} />
              <span className="text-muted">Resend Phone OTP</span>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center flex-column mt-4">
      {verificationField.length > 0 ? (
        <>
          {verificationField.map(item => item === "email" ? EmailOTPInput() : PhoneOTPInput())}

          <button
            type="button"
            disabled={loading}
            onClick={handleOnVerify}
            className="btn btn-primary w-50 mt-3"
          >
            {loading ? (
              <>
                <Spinner size="sm" /> Verifying...
              </>
            ) : (
              "Verify"
            )}
          </button>
        </>
      ) : (<>
        {EmailOTPInput()}
        {PhoneOTPInput()}

        <button
          type="button"
          disabled={loading}
          onClick={handleOnVerify}
          className="btn btn-primary w-50 mt-3"
        >
          {loading ? (
            <>
              <Spinner size="sm" /> Verifying...
            </>
          ) : (
            "Verify"
          )}
        </button>

      </>)}

    </div>
  );
};

export default VisitorSignupOtpForm;
