import React, { useState, useEffect, useMemo, useCallback } from "react";
import { getGatepassListApi } from "../../../services/GatepassService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from "../../constant/TableLoader";
import { connect } from "react-redux";
import DebounceSearchInput from "../../constant/DebounceSearchInput";
import { formatDateRange } from "../../../utils";
import ApprovalInfo from "./ApprovalInfoModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";
import CustomFlatipckrDateRange from "../../constant/CustomFlatipckrDateRange";

const GatepassPage = ({ userRole, user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    visiting_start_date: "",
    visiting_end_date: "",
    status: "",
    query: "",
    department: "",
  });
  const [isClearAll, setIsClearAll] = useState(false);
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const recordsPage = 25;

  const location = useLocation();
  const navigate = useNavigate();

  const updateFilterFromLocation = useMemo(() => {
    if (location.state?.gatepass_status) {
      const gatepass_status = location.state.gatepass_status;
      if (["Pending", "Approved", "Active"].includes(gatepass_status)) {
        return {
          status: gatepass_status,
          ...(gatepass_status === "Active"
            ? { visiting_start_date: "", visiting_end_date: "" }
            : {}),
        };
      }
    }
    return null;
  }, [location.state]);

  const fetchData = useCallback(() => {
    // If filter is already updated by location, skip the API call
    if (isFilterUpdated || !updateFilterFromLocation) {
      setIsLoading(true);
      CancelRequest();
      getGatepassListApi(currentPage, recordsPage, filter)
        .then((resp) => {
          setData(resp.data.data);
          setTotal(resp.data.total);
        })
        .catch((error) => {
          console.error(error.response?.data?.message || "Error fetching data");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPage, filter, isFilterUpdated, recordsPage, updateFilterFromLocation]);

  useEffect(() => {
    // Update the filter from location state
    if (updateFilterFromLocation) {
      setFilter((prev) => ({ ...prev, ...updateFilterFromLocation }));
      setIsFilterUpdated(true);
      setCurrentPage(1);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [updateFilterFromLocation, location.pathname, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleDateChange = (visiting_start_date, visiting_end_date) => {
    if (
      visiting_start_date === filter.visiting_start_date &&
      visiting_end_date === filter.visiting_end_date
    ) {
      return;
    }
    setFilter((prev) => ({
      ...prev,
      visiting_start_date,
      visiting_end_date,
    }));
    setCurrentPage(1);
  };

  const handleSelectChange = (name, value, _) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsClearAll(false);
    setCurrentPage(1);
  };

  const handleSearchChange = (value) => {
    setFilter((prev) => ({
      ...prev,
      query: value,
    }));
    setCurrentPage(1);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-header">
                <h3 className="mb-sm-0 mb-2">Gatepass List</h3>
                <div className="table-left-header">
                  <div className="form-group me-sm-3 me-0">
                    <DebounceSearchInput
                      handleSearchChange={handleSearchChange}
                    />
                  </div>
                  {userRole === "Visitor" && (
                    <button
                      className="btn btn-sm btn-primary mb-sm-0 mb-3"
                      onClick={() => navigate("/apply-gatepass")}
                    >
                      Apply Gatepass
                    </button>
                  )}
                </div>
              </div>
              <div className="row my-2 px-3">
                <div className="col-md-4 form-group">
                  {userRole != "Admin" &&
                  !(userRole == "CISF" && user.is_an_approver) ? (
                    <DynamicSelectInput
                      setParentInputValue={handleSelectChange}
                      endpoint_name="search/department"
                      name={"department"}
                      label={"Department"}
                      labelHint={false}
                      id={"department"}
                      isClearable={true}
                      placeholder={"Select Department"}
                    />
                  ) : null}
                </div>

                <div className="col-md-4 form-group">
                  <StaticSelectInput
                    optionsList={[{ label: "Approved", value: "Approved" }, { label: "Pending", value: "Pending" }, { label: "Rejected", value: "Rejected" }, { label: "Active", value: "Active" }]}
                    setParentInputValue={handleSelectChange}
                    name="status"
                    isClearable={true}
                    placeholder="Select Status"
                    label={"Status"}
                    labelHint={false}
                    defaultValue={filter.status ? { label: filter.status, value: filter.status } : null}
                    id="status"
                    isSetEmpty={isClearAll}
                  />
                </div>

                <div className="col-md-4 form-group">
                  <CustomFlatipckrDateRange
                    handleOnDateSelect={handleDateChange}
                    isSetClear={filter.status === "Active"}
                  />
                </div>
              </div>
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <table
                  id="empoloyees-tblwrapper"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Gatepass Number</th>
                      {userRole !== "Visitor" && <th>Visitor</th>}
                      <th>Visiting Date</th>
                      <th>Department</th>
                      <th>Officer To Meet </th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <TableLoaderEffect
                        colLength={userRole !== "Visitor" ? 6 : 5}
                      />
                    ) : (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-primary fw-bold c-pointer"
                              onClick={() => navigate(`/gatepass/${item.id}`)}
                            >
                              {item.gatepass_number}
                            </span>
                          </td>
                          {userRole !== "Visitor" && (
                            <td>
                              <Link to={`/visitor/${item.visitor.id}`}>
                                {item.visitor.name}
                              </Link>
                            </td>
                          )}
                          <td>
                            {formatDateRange(
                              item.visiting_start_date,
                              item.visiting_end_date
                            )}
                          </td>
                          <td>
                            {item.approval[0].department.name}{" "}
                            {item.approval.length > 1 && (
                              <ApprovalInfo approvalList={item.approval} />
                            )}
                          </td>
                          <td>{item.approval[0].officer.name}</td>
                          <td>
                            {item.approval[0].status === "Approved" ? (
                              <span className="badge light border-0 badge-success badge-sm">
                                Approved
                              </span>
                            ) : item.approval[0].status === "Pending" ? (
                              <span className="badge light border-0 badge-warning badge-sm">
                                Pending
                              </span>
                            ) : (
                              <span className="badge light border-0 badge-danger badge-sm">
                                Rejected
                              </span>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <div className="pagination-wrapper">
                  <Pagination
                    totalRecords={total}
                    recordsPerPage={recordsPage}
                    handlePageClick={handlePagination}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth?.userRole,
  user: state.auth?.user,
});

export default connect(mapStateToProps)(GatepassPage);
