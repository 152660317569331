import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Placeholder } from "react-bootstrap";
import { connect } from "react-redux";
import { getVisitorApi } from "../../../services/UserService";
import TextAvatar from "../../constant/TextAvatar";
import { getInitialChar } from "../../../utils";
import "./Visitor.css"
import VisitorTab from "./VisitorTab/VisitorTab"

const VisitorDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function VisitorDetailsPage({ userRole }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getVisitorApi(id);
      const resp_data = resp.data.data;
      setData(resp_data);
    } catch (error) {
      console.error("Error fetching user:", error);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          {isLoading ? (
            <VisitorDetailsLoading />
          ) : data ? (
            <div>
              <div className="card">
                <div className="card-header">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm btn-circle"
                        onClick={() => navigate("/visitor")}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <h4 className="mb-0 ms-2">Visitor Details</h4>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                    <div>
                      <div className="d-flex align-items-center justify-content-center flex-column border-bottom">
                        <div>
                          {data.profile_photo ? (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${data.profile_photo}`}
                              alt="Profile"
                              style={{
                                height: "130px",
                                width: "130px",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <TextAvatar
                              text={getInitialChar(
                                `${data.first_name} ${data.visitor?.data?.last_name}`
                              )}
                              className="text-avatar-square"
                            />
                          )}
                        </div>
                        <h3 className="my-2">
                          {data.first_name} {data.last_name}
                        </h3>
                      </div>
                      <ul>
                        <li className="my-3">
                          <span className="fw-bold">Email: </span>
                          {data.email}
                        </li>
                        <li className="my-3">
                          <span className="fw-bold">Phone Number: </span>
                          {data.phone_number}
                        </li>
                        <li className="my-3">
                          <span className="fw-bold">Aadhaar Number: </span>
                          {data.aadhaar_number}
                        </li>
                        <li className="my-3">
                          <span className="fw-bold">DOB: </span>
                          {data.dob}
                        </li>
                        <li className="my-3">
                          <span className="fw-bold">Gender: </span>
                          {data.gender}
                        </li>
                        <li className="my-3">
                          <span className="fw-bold">Status: </span>
                          <span
                            className={`badge light border-0 ${
                              data.status === "Active"
                                ? "badge-success"
                                : "badge-danger"
                            } badge-sm ms-2`}
                          >
                            {data.status}
                          </span>
                        </li>
                        <li className="my-3">
                          <span className="fw-bold">Company Name: </span>
                          {data.company_name}
                        </li>

                        <li className="my-3">
                          <span className="fw-bold">Designation: </span>
                          {data.designation}
                        </li> 
                      </ul>
                    </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card">
              <div className="card-body">
                <h4 className="text-center text-danger">
                  ⚠️ User Not Found!
                  <br /> ID {id}
                </h4>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-8">
        <VisitorTab />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "Admin",
});

export default connect(mapStateToProps)(VisitorDetailsPage);