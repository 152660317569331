import React, { useState, useEffect, useCallback } from "react";
import DataLoader from "../Loader";
import { getSACISFMostVisitngCountApi } from "../../../../services/DashboardService";
import { Link } from "react-router-dom";
import { MostVisitDepartmentPieChart } from "../VisitorDashbaord/VisitorMostVisiting";

const SACISFMostVisitingPage = ({ user }) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getSACISFMostVisitngCountApi();
      const result = response.data.results;
      setData(result);
    } catch (err) {
      console.log("Error", err);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isLoading ? (
    <div className="row">
      <div className="col-xl-4 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-4 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
      <div className="col-xl-4 col-sm-6">
        <div className="card card-body">
          <DataLoader />
        </div>
      </div>
    </div>
  ) : data === null ? (
    <div>
      <h3>Something went wrong ...🤕</h3>
    </div>
  ) : (
    <div className="row">
      <div className="col-xl-4 col-sm-6">
        <div className="card">
          <div className="card-body p-0 pb-4">
            <div className="table-responsive active-projects shorting">
              <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                <h4 className="heading mb-0">Most Officer To Meet</h4>
              </div>

              <div
                id="projects-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="projects-tbl"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Officer</th>
                      <th>Meet Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.top_officers.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="fw-semibold">
                              {item.first_name} {item.last_name}
                            </span>
                            <span>{item.email} </span>
                          </div>
                        </td>
                        <td>
                          <span className="fw-bold">{item.approval_count}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-4 col-sm-6">
        <div className="card">
          <div className="card-body p-0 pb-4">
            <div className="table-responsive active-projects shorting">
              <div className="tbl-caption d-flex justify-content-between flex-wrap align-items-center">
                <h4 className="heading mb-0">Most Frequent visitor</h4>
              </div>

              <div
                id="projects-tbl_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <table
                  id="projects-tbl"
                  className="table ItemsCheckboxSec dataTable no-footer mb-0"
                >
                  <thead>
                    <tr>
                      <th>Visitor</th>
                      <th>Visit Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.frequent_visitor.length > 0 ? (
                      data.frequent_visitor.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="d-flex flex-column">
                              <Link
                                to={`/visitor/${item.id}`}
                                className="fw-semibold"
                              >
                                {item.first_name}
                                {item.last_name}
                              </Link>
                              <span>{item.email}</span>
                            </div>
                          </td>
                          <td>
                            <span className="fw-bold">{item.visit_count}</span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} style={{ textAlign: "center" }}>
                          <h4>No Record Found 🌥️</h4>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-4 col-sm-6">
        <MostVisitDepartmentPieChart data={data.top_departments} />
      </div>
    </div>
  );
};

export default SACISFMostVisitingPage;
