import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Modal, Spinner } from "react-bootstrap";
import { updateUserProfilePhoto } from "../../../services/AuthService";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../../store/actions/AuthActions";

function ChangeProfilePhoto({ isPhoto }) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [isRemovePhoto, setIsRemovePhoto] = useState(false);
  const dispatch = useDispatch();

  const toggleModal = () => {
    if (!showModal) {
      setSelectedFile(null);
      setIsSubmitLoading(false);
    }
    setShowModal(!showModal);
  };

  const toggleRemove = () => {
    setIsRemovePhoto(!isRemovePhoto);
  };

  const fileInputRef = useRef(null);

  const handleFiles = (fileList) => {
    if (fileList.length) {
      const file = fileList[0];
      const allowed_file_type = ["JPG", "JPEG", "PNG"];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (allowed_file_type.includes(file_ext)) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedFileUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("choose a valid file");
      }
    } else {
      toast.error("choose a file");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  const handleFileInputChange = (e) => {
    handleFiles(e.target.files);
  };

  const handleFileRemove = (e) => {
    setSelectedFile(null);
    e.stopPropagation();
  };

  const handleUpload = async () => {
    const formBody = new FormData();
    formBody.append("profile_photo", selectedFile);
    try {
      setIsSubmitLoading(true);
      const resp = await updateUserProfilePhoto(formBody);
      const { results, message } = resp.data;
      dispatch(updateProfileAction({ profile_photo: results }));
      toast.success(message);
      toggleModal(false);
      setIsSubmitLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSubmitLoading(false);
    }
  };

  const handleRemove = async () => {
    const formBody = new FormData();
    formBody.append("remove_profile", "YES");
    try {
      setIsSubmitLoading(true);
      const resp = await updateUserProfilePhoto(formBody);
      const { results, message } = resp.data;
      dispatch(updateProfileAction({ profile_photo: results }));
      toast.success(message);
      toggleModal(false);
      setIsSubmitLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        {isRemovePhoto ? (
          <div className="d-flex align-items-center flex-column border rounded mt-2 p-2">
            <span className="text-center">
              Confirm To Remove Profile Photo.
            </span>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <button
                className="btn btn-danger btn-sm"
                disabled={isSubmitLoading}
                onClick={handleRemove}
              >
                YES
              </button>
              <button
                className="btn btn-outline-primary btn-sm  ms-2"
                disabled={isSubmitLoading}
                onClick={toggleRemove}
              >
                NO
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: -2,
              width: "101%",
              color: "#fff",
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              cursor: "pointer",
            }}
          >
            <button
              className="btn btn-outline-primary btn-sm border-0 text-white"
              onClick={toggleModal}
            >
              {isPhoto ? "Change Profile photo" : "Change Photo"}
            </button>
            {/*           {isPhoto &&
            <button className="btn btn-outline-danger btn-sm  ms-2" onClick={toggleRemove}>
              <i className="fa-solid fa-x"></i>
            </button>
          } */}
          </div>
        )}
      </div>

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={toggleModal}
        //keyboard={"static"}
        //backdrop={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Change Profile Photo
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <div>
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className={`dropzone dropzone-sm ${
                  selectedFile === null && "no-files"
                }`}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  accept={".jpg, .jpeg, .png"}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
                {selectedFile ? (
                  <div className="file-list">
                    {selectedFileUrl && (
                      <img
                        src={selectedFileUrl}
                        alt="Selected"
                        style={{ maxWidth: "100%" }}
                      />
                    )}
                    <div className="file-item">
                      <span className="file-name">{selectedFile.name}</span>
                      <span
                        className="file-remove"
                        onClick={(e) => handleFileRemove(e)}
                      >
                        <i className="fa-regular fa-circle-xmark text-danger"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <p>
                    Drag and drop files here, or click to select file
                    <br />
                    <strong>JPEG, JPG & PNG</strong> files are allowed.
                  </p>
                )}
              </div>

              <div className="d-flex align-items-center justify-content-center mt-4">
                <button
                  className="btn btn-primary btn-block w-50"
                  disabled={isSubmitLoading || selectedFile === null}
                  onClick={handleUpload}
                >
                  {isSubmitLoading ? (
                    <span>
                      <Spinner animation="border" size="sm" /> Uploadading ...
                    </span>
                  ) : (
                    "Upload"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChangeProfilePhoto;
