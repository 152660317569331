import React, { useState, useEffect, useCallback } from "react";
import { getUserListApi } from "../../../services/UserService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import UserAddModal from "./AddUserModal";
import { connect } from "react-redux";
import TableLoaderEffect from '../../constant/TableLoader';
import DebounceSearchInput from "../../constant/DebounceSearchInput";
import { SVGICON } from "../../constant/theme";
import DynamicSelectInput from "../../constant/SelectInput";


const UserPage = ({ userRole }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState({
        query: "",
        department: "",
        designation: ""
    });
    const recordsPage = 25;

    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        CancelRequest();
        setIsLoading(true);
        getUserListApi(currentPage, recordsPage, filter)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
            }).finally(() => {
                setIsLoading(false);
            });
    }, [currentPage, filter]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSelectChange = (name, value, _) => {
        setFilter((prev) => ({
            ...prev,
            [name]: value
        }));
        setCurrentPage(1);
    };

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = (value) => {
        if (value.length > 0) CancelRequest();
        setFilter((prev) => ({
            ...prev,
            query: value
        }));
        setCurrentPage(1);
    };


    const handleAfterCreate = (newData) => {
        setData((prevData) => [newData, ...prevData]);
        setTotal((prevTotal) => prevTotal + 1);
    };

    const handleAfterUpdate = (updatedData) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
        );
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-header">
                                <h3 className="mb-0">User List</h3>
                                <div className='table-left-header'>
                                    <div className='form-group me-3'>
                                        <DebounceSearchInput handleSearchChange={handleSearchChange} />
                                    </div>
                                    {userRole === "SuperAdmin" ?
                                        <UserAddModal handleAfterCreate={handleAfterCreate} />
                                        : null}

                                </div>
                            </div>

                            <div className="row my-2 px-3">
                                <div className="col-md-4 form-group">

                                </div>

                                <div className="col-md-4 form-group">
                                    {userRole !== "Admin" ?
                                        <DynamicSelectInput
                                            setParentInputValue={handleSelectChange}
                                            endpoint_name="search/department"
                                            name={"department"}
                                            label={"Department"}
                                            labelHint={false}
                                            id={"department"}
                                            isClearable={true}
                                            placeholder={"Select Department"}
                                        />
                                        : null}
                                </div>
                                <div className="col-md-4 form-group">
                                    <DynamicSelectInput
                                        setParentInputValue={handleSelectChange}
                                        endpoint_name="search/designation"
                                        name={"designation"}
                                        label={"Designation"}
                                        labelHint={false}
                                        id={"designation"}
                                        isClearable={true}
                                        placeholder={"Select Designation"}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Designation</th>
                                            <th>Department</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <span className="text-primary fw-bold c-pointer"
                                                        onClick={() => navigate(`/user/${item.id}`)}>{item.first_name} {item.last_name}
                                                    </span>

                                                    {item.is_hod ? <span className="ms-1 text-danger">{SVGICON.HODIcon}</span> : item.is_an_approver ? <span className="ms-1 text-success">{SVGICON.VerifyIcon}</span> : null}

                                                </td>
                                                <td>{item.email}</td>
                                                <td>{item.phone_number}</td>
                                                <td>{item.designation.name}</td>
                                                <td>{item.department.name}</td>
                                                <td>{item.status === "Active" ? <span className='badge light border-0 badge-success badge-sm'>Active</span> :
                                                    <span className='badge light border-0 badge-danger badge-sm'>Inactive</span>}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            
                            {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                currentPage={currentPage}
                                recordsPage={recordsPage}
                                dataLength={total}
                                handlePagination={handlePagination}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = (state) => ({
    userRole: state.auth.user?.role || "Visitor"
});
export default connect(mapStateToProps)(UserPage);
