import * as Yup from "yup";

const now = new Date();
const todayNoon = new Date();
todayNoon.setHours(19, 0, 0, 0);

const AddGatepassValidationSchema = Yup.object().shape({
    visiting_start_date: Yup.string()
    .required("Visiting start date is required")
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Visiting start date must be in the format YYYY-MM-DD"
    )
    .test(
      "is-valid-start-date",
      "Visiting start date must be greater than today at 12 PM",
      function (value) {
        if (value) {
          const startDate = new Date(value);

          // Check if the start date is today but after 12 PM
          if (startDate.toDateString() === now.toDateString()) {
            return now <= todayNoon;
          }

          // Ensure the start date is after today
          return startDate > now;
        }
        return false;
      }
    ),

  visiting_end_date: Yup.string()
    .required("Visiting end date is required")
    .matches(
      /^\d{4}-\d{2}-\d{2}$/,
      "Visiting end date must be in the format YYYY-MM-DD"
    )
    .test(
      "is-valid-end-date",
      "Visiting end date must be at most 1 day after the start date",
      function (value) {
        const { visiting_start_date } = this.parent;

        if (value && visiting_start_date) {
          const startDate = new Date(visiting_start_date);
          const endDate = new Date(value);

          // Calculate the max end date (1 day after the start date)
          const maxEndDate = new Date(startDate);
          maxEndDate.setDate(startDate.getDate() + 1);

          return endDate >= startDate && endDate <= maxEndDate;
        }
        return false;
      }
    ),

    vehicle_id: Yup.string().optional(),
    vehicle_available: Yup.string().required("Select vehicle availability"),

    vehicle_number: Yup.string().when("vehicle_available", ([val], schema) => {
        return val === "YES"
            ? schema.required("Vehicle number is required")
            : schema.notRequired();
    }),

    driver_dl_number: Yup.string().when(
        "vehicle_available",
        ([val], schema) => {
            return val === "YES"
                ? schema.required("Driver DL number is required")
                : schema.notRequired();
        }
    ),

    drive_type: Yup.string().when("vehicle_available", ([val], schema) => {
        return val === "YES"
            ? schema.required("Select Drive Type")
            : schema.notRequired();
    }),

    driver_name: Yup.string().when("drive_type", ([val], schema) => {
        return val === "Other"
            ? schema.required("Driver name is required")
            : schema.notRequired();
    }),

    driver_aadhaar_number: Yup.string().when("drive_type", ([val], schema) => {
        return val === "Other"
            ? schema.required("Driver Aadhaar number is required").matches(/^\d{12}$/, "Driver Aadhaar number must be exactly 12 digits")
            : schema.notRequired();
    }),

    driver_profile_photo: Yup.mixed()
        .nullable()
        .when(["drive_type", "vehicle_id"], ([driveType, vehicleId], schema) => {
            return driveType === "Other" && !vehicleId
                ? schema
                    .required("Driver Profile Photo is required")
                    .test(
                        "fileSize",
                        "File size must be below 1MB",
                        (value) => value && value.size <= 1024 * 1024
                    )
                    .test(
                        "fileFormat",
                        "Only JPG/PNG files are allowed",
                        (value) =>
                            value && ["image/jpeg", "image/png"].includes(value.type)
                    )
                : schema.notRequired();
        }),

    driver_aadhaar_photo: Yup.mixed()
        .nullable()
        .when(["drive_type", "vehicle_id"], ([driveType, vehicleId], schema) => {
            return driveType === "Other" && !vehicleId
                ? schema
                    .required("Driver Aadhaar Photo is required")
                    .test(
                        "fileSize",
                        "File size must be below 1MB",
                        (value) => value && value.size <= 1024 * 1024
                    )
                    .test(
                        "fileFormat",
                        "Only JPG/PNG files are allowed",
                        (value) =>
                            value && ["image/jpeg", "image/png"].includes(value.type)
                    )
                : schema.notRequired();
        }),

    visitingDepartment: Yup.array()
        .of(
            Yup.object().shape({
                department: Yup.string().required("Select visiting department"),
                officer: Yup.string().required("Select officer to meet"),
            })
        )
        .test(
            "validate-tags",
            "Tags array should not be empty if tags are provided",
            (tags) => tags.length === 0 || tags.length > 0
        ),

    coVisitors: Yup.array()
        .of(
            Yup.object().shape({
                covisitor_id: Yup.string().optional(),
                first_name: Yup.string().required("First name is required"),
                last_name: Yup.string().required("Last name is required"),
                email: Yup.string()
                    .email("Invalid email format")
                    .required("Email is required"),
                phone_number: Yup.string()
                    .required("Phone number is required")
                    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
                aadhaar_number: Yup.string()
                    .required("Aadhaar is required")
                    .matches(/^\d{12}$/, "Aadhaar number must be exactly 12 digits"),
                dob: Yup.string()
                    .required("Date of Birth is required")
                    .matches(
                        /^\d{4}-\d{2}-\d{2}$/,
                        "Date of Birth must be in the format YYYY-MM-DD"
                    )
                    .test(
                        "age",
                        "Visitor must be at least 14 years old",
                        function (value) {
                            if (!value) return false;
                            const today = new Date();
                            const birthDate = new Date(value);
                            const age = today.getFullYear() - birthDate.getFullYear();
                            return age >= 14;
                        }
                    ),
                gender: Yup.string().required("Select gender"),
                company_name: Yup.string().required("Company name is required"),
                designation: Yup.string().required("Designation is required"),
                profile_photo: Yup.mixed().nullable().when(["covisitor_id"], ([covisitor_id], schema) => {
                    return !covisitor_id
                        ? schema
                            .required("Profile Photo is required")
                            .test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024)
                            .test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type))
                        : schema.notRequired()
                }),
                aadhaar_photo: Yup.mixed().nullable().when(["covisitor_id"], ([covisitor_id], schema) => {
                    return !covisitor_id
                        ? schema
                            .required("Aadhaar Photo is required")
                            .test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024)
                            .test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type))
                        : schema.notRequired()
                }),

            })
        )
        .test(
            "validate-tags",
            "Tags array should not be empty if tags are provided",
            (tags) => tags.length === 0 || tags.length > 0
        ),

    returnableItems: Yup.array()
        .of(
            Yup.object().shape({
                item_id: Yup.string().optional(),
                item_name: Yup.string().required("Item name is required"),
                item_description: Yup.string().required(
                    "Item description is required"
                ),
                item_photo: Yup.mixed().nullable().when(["item_id"], ([item_id], schema) => {
                    return !item_id
                        ? schema
                            .required("Item Photo is required")
                            .test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024)
                            .test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type))
                        : schema.notRequired()
                }),
            })
        )
        .test(
            "validate-tags",
            "Tags array should not be empty if tags are provided",
            (tags) => tags.length === 0 || tags.length > 0
        ),
    purpose_of_visiting: Yup.string().required(
        "Purpose of visiting is required"
    ),
});


export default AddGatepassValidationSchema




// import * as Yup from "yup";

// const today = new Date();
// const AddGatepassValidationSchema = Yup.object().shape({
//     visiting_start_date: Yup.string()
//         .required("Visiting start date is required")
//         .matches(
//             /^\d{4}-\d{2}-\d{2}$/,
//             "Visiting start date must be in the format YYYY-MM-DD"
//         )
//         .test(
//             "is-valid-start-date",
//             "Visiting start date must be greater than today",
//             function (value) {
//                 if (value) {
//                     const startDate = new Date(value);
//                     return startDate >= new Date(today);
//                 }
//                 return false;
//             }
//         ),

//     visiting_end_date: Yup.string()
//         .required("Visiting end date is required")
//         .matches(
//             /^\d{4}-\d{2}-\d{2}$/,
//             "Visiting end date must be in the format YYYY-MM-DD"
//         )
//         .test(
//             "is-valid-end-date",
//             "Visiting end date must be at most 1 day after the start date",
//             function (value) {
//                 const { visiting_start_date } = this.parent;
//                 if (value && visiting_start_date) {
//                     const startDate = new Date(visiting_start_date);
//                     const endDate = new Date(value);
//                     const maxEndDate = new Date(startDate);
//                     maxEndDate.setDate(startDate.getDate() + 1);
//                     return endDate >= startDate && endDate <= maxEndDate;
//                 }
//                 return false;
//             }
//         ),

//     vehicle_id: Yup.string().optional(),
//     vehicle_available: Yup.string().required("Select vehicle availability"),

//     vehicle_number: Yup.string().when(
//         ["vehicle_available", "vehicle_id"],
//         ([vehicle_available, vehicle_id], schema) => {
//             return vehicle_available === "YES" && !vehicle_id
//                 ? schema.required("Vehicle number is required")
//                 : schema.notRequired();
//         }
//     ),

//     driver_dl_number: Yup.string().when(
//         ["vehicle_available", "vehicle_id"],
//         ([vehicle_available, vehicle_id], schema) => {
//             return vehicle_available === "YES" && !vehicle_id
//                 ? schema.required("Driver DL number is required")
//                 : schema.notRequired();
//         }
//     ),

//     drive_type: Yup.string().when(
//         ["vehicle_available", "vehicle_id"],
//         ([vehicle_available, vehicle_id], schema) => {
//             return vehicle_available === "YES" && !vehicle_id
//                 ? schema.required("Select Drive Type")
//                 : schema.notRequired();
//         }
//     ),

//     driver_name: Yup.string().when(
//         ["drive_type", "vehicle_id"],
//         ([drive_type, vehicle_id], schema) => {
//             return drive_type === "Other" && !vehicle_id
//                 ? schema.required("Driver name is required")
//                 : schema.notRequired();
//         }
//     ),

//     driver_aadhaar_number: Yup.string().when(
//         ["drive_type", "vehicle_id"],
//         ([drive_type, vehicle_id], schema) => {
//             return drive_type === "Other" && !vehicle_id
//                 ? schema
//                     .matches(/^\d{12}$/, "Driver Aadhaar number must be exactly 12 digits")
//                     .required("Driver Aadhaar number is required")
//                 : schema.notRequired();
//         }
//     ),

//     driver_profile_photo: Yup.mixed().nullable().when(
//         ["drive_type", "vehicle_id"],
//         ([drive_type, vehicle_id], schema) => {
//             return drive_type === "Other" && !vehicle_id
//                 ? schema
//                     .required("Driver Profile Photo is required")
//                     .test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024)
//                     .test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type))
//                 : schema.notRequired();
//         }
//     ),

//     driver_aadhaar_photo: Yup.mixed().nullable().when(
//         ["drive_type", "vehicle_id"],
//         ([drive_type, vehicle_id], schema) => {
//             return drive_type === "Other" && !vehicle_id
//                 ? schema
//                     .required("Driver Aadhaar Photo is required")
//                     .test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024)
//                     .test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type))
//                 : schema.notRequired();
//         }
//     ),

//     visitingDepartment: Yup.array()
//         .of(
//             Yup.object().shape({
//                 department: Yup.string().required("Select visiting department"),
//                 officer: Yup.string().required("Select officer to meet"),
//             })
//         )
//         .test("validate-tags", "Tags array should not be empty if tags are provided", (tags) => tags.length === 0 || tags.length > 0),

//     coVisitors: Yup.array()
//         .of(
//             Yup.object().shape({
//                 covisitor_id: Yup.string().optional(),
//                 first_name: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("First name is required") : schema.notRequired()),
//                 last_name: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Last name is required") : schema.notRequired()),
//                 email: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.email("Invalid email format").required("Email is required") : schema.notRequired()),
//                 phone_number: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.matches(/^\d{10}$/, "Phone number must be exactly 10 digits").required("Phone number is required") : schema.notRequired()),
//                 aadhaar_number: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.matches(/^\d{12}$/, "Aadhaar number must be exactly 12 digits").required("Aadhaar is required") : schema.notRequired()),
//                 dob: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Date of Birth is required").matches(/^\d{4}-\d{2}-\d{2}$/, "Date of Birth must be in the format YYYY-MM-DD").test("age", "Visitor must be at least 14 years old", function (value) {
//                     if (!value) return false;
//                     const today = new Date();
//                     const birthDate = new Date(value);
//                     const age = today.getFullYear() - birthDate.getFullYear();
//                     return age >= 14;
//                 }) : schema.notRequired()),
//                 gender: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Select gender") : schema.notRequired()),
//                 company_name: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Company name is required") : schema.notRequired()),
//                 designation: Yup.string().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Designation is required") : schema.notRequired()),
//                 profile_photo: Yup.mixed().nullable().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Profile photo is required").test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024).test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type)) : schema.notRequired()),
//                 aadhaar_photo: Yup.mixed().nullable().when("covisitor_id", (covisitor_id, schema) => !covisitor_id ? schema.required("Aadhaar photo is required").test("fileSize", "File size must be below 1MB", (value) => value && value.size <= 1024 * 1024).test("fileFormat", "Only JPG/PNG files are allowed", (value) => value && ["image/jpeg", "image/png"].includes(value.type)) : schema.notRequired()),
//             })
//         )
//         .test("validate-tags", "Tags array should not be empty if tags are provided", (tags) => tags.length === 0 || tags.length > 0),

//     returnableItems: Yup.array()
//         .of(
//             Yup.object().shape({
//                 item_id: Yup.string().optional(),
//                 item_name: Yup.string().when("item_id", (item_id, schema) => {
//                     return !item_id ? schema.required("Item name is required") : schema.notRequired()
//                 }),
//                 item_description: Yup.string().when("item_id", (item_id, schema) => !item_id ? schema.required("Item description is required") : schema.notRequired()),
//                 item_quantity: Yup.string().when("item_id", (item_id, schema) => !item_id ? schema.required("Item quantity is required") : schema.notRequired()),
//                 item_weight: Yup.string().when("item_id", (item_id, schema) => !item_id ? schema.required("Item weight is required") : schema.notRequired()),
//             })
//         )
//         .test("validate-tags", "Tags array should not be empty if tags are provided", (tags) => tags.length === 0 || tags.length > 0),
// });

// export default AddGatepassValidationSchema;
