import React, { useCallback, useState } from "react";
import debounce from 'lodash.debounce';


const DebounceSearchInput = ({ handleSearchChange }) => {
  const [inputValue, setInputValue] = useState("");

  const debouncedSearch = useCallback(
    debounce((value) => {
      handleSearchChange(value);
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    debouncedSearch(value);
  };

  return (
    <input
      type="search"
      name="search"
      className="form-control"
      placeholder="Search.."
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

export default DebounceSearchInput;
