import {
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,
    UPDATE_PROFILE,
    AUTH_CHECK_CONFIRMED_ACTION,
    NAVTOGGLE
} from '../actions/AuthActions';

import {toast} from 'react-toastify'

const initialState = {
    user: null,
    isCheckingAuth: true,
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};

export function AuthReducer(state = initialState, action) {
    if (action.type === LOGIN_CONFIRMED_ACTION) {
        return {
            ...state,
            user: action.payload,
            isCheckingAuth: false,
            showLoading: false,
        };
    } else if (action.type === AUTH_CHECK_CONFIRMED_ACTION) {
        return {
            ...state,
            isCheckingAuth: action.payload
        };

    } else if (action.type === UPDATE_PROFILE) {
        return {
            ...state,
            user: { ...state.user, ...action.payload },
            showLoading: false,
        };
    }

    else if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            isCheckingAuth: false,
            user: null,
        };
    }

    else if (action.type === LOGIN_FAILED_ACTION) {
        toast.error(action.payload, {position: "top-left"})
        return {
            ...state,
            showLoading: false,
        };
    }

    else if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}

const initial = false
export function toggleMenu(state = initial, action) {
    if (action.type === NAVTOGGLE) {
        return !state
    }
    return state;
}


